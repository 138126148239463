import React from "react";

export const BixIcon = (props) => {
  const { color, width = 26, height = 26 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
        <path
          d="M5.02273 20.9773H3.25V15.6591H8.56818V17.4318H6.37C7.79705 19.568 10.2345 20.9773 13 20.9773C17.4052 20.9773 20.9773 17.4052 20.9773 13H22.75C22.75 18.3891 18.3891 22.75 13 22.75C9.70273 22.75 6.78659 21.1102 5.02273 18.6107V20.9773ZM3.25 13C3.25 7.61091 7.61091 3.25 13 3.25C16.2973 3.25 19.2134 4.88977 20.9773 7.38932V5.02273H22.75V10.3409H17.4318V8.56818H19.63C18.203 6.43205 15.7655 5.02273 13 5.02273C8.59477 5.02273 5.02273 8.59477 5.02273 13H3.25Z"
          fill={color}
        />
        <path
          d="M15.8635 15.411C15.6661 15.4117 15.4706 15.3733 15.2882 15.298C15.1058 15.2228 14.9401 15.1121 14.8008 14.9724L13.2657 13.4364C13.2114 13.3844 13.1392 13.3555 13.0641 13.3555C12.989 13.3555 12.9167 13.3844 12.8624 13.4364L11.3222 14.9766C11.1829 15.1165 11.0173 15.2274 10.8349 15.3029C10.6525 15.3784 10.4569 15.417 10.2595 15.4165H9.95898L11.9041 17.3617C12.1959 17.6528 12.5912 17.8163 13.0033 17.8163C13.4154 17.8163 13.8107 17.6528 14.1025 17.3617L16.0515 15.4123L15.8635 15.411Z"
          fill={color}
        />
        <path
          d="M10.2603 9.9823C10.4577 9.98178 10.6533 10.0204 10.8357 10.0959C11.0181 10.1714 11.1837 10.2823 11.323 10.4222L12.8632 11.9628C12.9168 12.0162 12.9893 12.0461 13.0649 12.0461C13.1405 12.0461 13.213 12.0162 13.2665 11.9628L14.8013 10.4281C14.9404 10.288 15.106 10.1769 15.2884 10.1014C15.4709 10.0258 15.6665 9.98719 15.8639 9.98781H16.0487L14.0997 8.03878C13.9554 7.8944 13.784 7.77987 13.5954 7.70173C13.4068 7.62359 13.2047 7.58337 13.0005 7.58337C12.7964 7.58337 12.5942 7.62359 12.4056 7.70173C12.2171 7.77987 12.0457 7.8944 11.9014 8.03878L9.95947 9.9823H10.2603Z"
          fill={color}
        />
        <path
          d="M17.6608 11.6006L16.483 10.4228C16.4565 10.4336 16.4281 10.4394 16.3994 10.4396H15.8638C15.5851 10.4403 15.318 10.551 15.1204 10.7476L13.5857 12.2814C13.4475 12.4193 13.2602 12.4968 13.0649 12.4968C12.8696 12.4968 12.6823 12.4193 12.5441 12.2814L11.0036 10.7418C10.8061 10.5451 10.5389 10.4343 10.2602 10.4335H9.60273C9.57564 10.4329 9.54886 10.4275 9.52363 10.4176L8.33839 11.6006C8.04728 11.8923 7.88379 12.2876 7.88379 12.6997C7.88379 13.1119 8.04728 13.5072 8.33839 13.7989L9.52104 14.9815C9.54622 14.9715 9.57302 14.9661 9.60014 14.9657H10.2602C10.5389 14.9648 10.8061 14.8541 11.0036 14.6574L12.5438 13.1171C12.8223 12.839 13.3076 12.839 13.5857 13.1171L15.1204 14.6515C15.318 14.8481 15.5851 14.9588 15.8638 14.9595H16.3994C16.4281 14.9597 16.4565 14.9654 16.483 14.9764L17.6608 13.7986C17.8052 13.6543 17.9197 13.4829 17.9979 13.2943C18.076 13.1057 18.1162 12.9036 18.1162 12.6994C18.1162 12.4953 18.076 12.2931 17.9979 12.1045C17.9197 11.9159 17.8052 11.7446 17.6608 11.6002"
          fill={color}
        />
    </svg>
  );
};
