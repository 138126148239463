const FETCH_CURRENCIES_REQUESTED = 'FETCH_CURRENCIES_REQUESTED';
const FETCH_MARKET_REQUESTED = 'FETCH_MARKET_REQUESTED';
const UPDATE_CURRENCIES = 'UPDATE_CURRENCIES';
const UPDATE_MARKET = 'UPDATE_MARKET';
const HANDLE_CURRENCIES_CACHE = 'HANDLE_CURRENCIES_CACHE';
const USE_CACHED_CURRENCIES = 'USE_CACHED_CURRENCIES';
export {
  FETCH_CURRENCIES_REQUESTED,
  FETCH_MARKET_REQUESTED,
  UPDATE_CURRENCIES,
  UPDATE_MARKET,
  HANDLE_CURRENCIES_CACHE,
  USE_CACHED_CURRENCIES,
}