import * as R from 'ramda';
import serializeBank from '../../../utils/serializeBankAccount';

const initialState = {};

const updateBankList = (state, { response }) => {
  const depositBankInfo = response[0];
  const { bankCode, branch, account, defaultPixKey, label } = depositBankInfo;
  const address = `${bankCode}|${branch}|${account}`
  const bankRawData = { address, label }
  const formattedAddress = R.reject(R.isEmpty, serializeBank(bankRawData));
  const bankInfo = {
    address: {
      pix:defaultPixKey,
      ...formattedAddress,
    }
  }
  return R.merge(state, bankInfo);
};


const bankListReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SERVICE_SUCCEED':
      switch (action.requestType) {
        case 'fetchBankList':
          return updateBankList(state, action);

        default:
          return state;
      }
    default:
      return state;
  }
};

export default bankListReducer;
