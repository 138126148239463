import React from 'react';
import {
  DescriptionOutlined,
  AnnouncementOutlined,
  AccountCircleOutlined,
  CheckCircleOutlined,
  HelpOutlined,
  MailOutlined,
  ExitToApp,
  LocalAtm,
  FlashOn,
  SwapHoriz,
  AccountBalance,
  AccountBalanceWallet,
  ContactSupport,
  Receipt,
  CreditCard,
} from "@material-ui/icons";

import store from '../redux/store';
import { endSession, addDialog, addAsideDialog } from '../redux/actions';

import { SUPPORT_MAIL, VARIANT } from '../config';

const getFirstCardAvailable = () => {
  const cards = store.getState().user.cards;
  if (cards.virtual) {
    return 'virtual';
  } else if (cards.plastic){
    return 'physical';
  } else if (!cards.loading){
    return 'noCard';
  }
}

const checkUserAcceptMgmTerm = () => {
  let acceptedTerm = "notAcceptedTerm";
  const acceptTermsObjectLocalStorage = localStorage.getItem("mgmTermAccepted");
  const acceptTermsObject = acceptTermsObjectLocalStorage ? JSON.parse(acceptTermsObjectLocalStorage) : {};
  const user = store.getState().user;
  const userId = user.profile.id;
  const acceptedLocalStorage = acceptTermsObject[userId];
  const hasReferrals = user.mgm && user.mgm.referrals && user.mgm.referrals.length > 0;

  if (acceptedLocalStorage || hasReferrals) {
    acceptedTerm = "indications";
  }
  return acceptedTerm;
}

const showUnavailableFeature = (title) => store.dispatch(addDialog({
  title,
  availableChoices: [
    {
      label: 'common.close',
      actionToTake: '',
      color: 'secondary',
      variant: 'raised',
    },
  ],
}));

const showAsideDialog = (component) => store.dispatch(addAsideDialog({
  renderComponent: component,
}));

const showMgmAcceptTermsDialog = () =>
  store.dispatch(
    addDialog({
      title: "",
      renderComponent: "acceptMgmTerms",
      availableChoices: [
        {
          label: "common.close",
          actionToTake: "",
          color: "secondary",
          variant: "raised",
        },
      ],
      componentProps: {

        paperClass: "acceptMgmTermsPaper",

        onAccept: () => {
          const mgmTermAcceptedObjectLocalStorage = localStorage.getItem("mgmTermAccepted");
          let mgmTermAcceptedObject =  mgmTermAcceptedObjectLocalStorage ? JSON.parse(mgmTermAcceptedObjectLocalStorage) : {};
          const userID = store.getState().user.profile.id;
          mgmTermAcceptedObject[userID] = true;
          localStorage.setItem("mgmTermAccepted", JSON.stringify(mgmTermAcceptedObject));
          window.location.href = "/mgm/indications";
        },
      },
    })
  );

const getVariantAvailableFeature = (name, subPage = '', isDemoAccount)  => {
  if (VARIANT === 'SIMULATOR') {
    return {
      onClick: () => showUnavailableFeature(`info.${name}`),
    };
  }else if( isDemoAccount ){
    return {
      onClick: () => showUnavailableFeature(`info.demoAccount.${name}`),
    };
    
  } else if (name === 'bix') {
    return {
      onClick: () => showAsideDialog("knowBix"),
    }
  }else if (name === 'card') {
    if(subPage === 'noCard'){
      return {
        onClick: () => showAsideDialog("cardSell"),
      }
    }else if (subPage === 'virtual' || subPage === 'physical'){
      return {
        to: `/${name}/${subPage}`,
        scene: name,
      };
    }return;
  } else if (name === 'mgm') {
    if (subPage === "notAcceptedTerm") {
      return { onClick: () => showMgmAcceptTermsDialog() };
    } else if (subPage === "indications") {
      return {
        to: `/${name}/${subPage}`,
        scene: name,
      };
    }
    return;
  }
  return {
    to: `/${name}/${subPage}`,
    scene: name,
  };
};

const appBarMenuItems = [
  {
    primary: 'screens.profile.title',
    to: '/profile/account',
    icon: <AccountCircleOutlined />,
    allowDemoAccount: false,
  }, {
    to: '/profile/operationalLimits',
    primary: 'screens.accountVerification.title',
    icon: <CheckCircleOutlined />,
    divider: true,
    allowDemoAccount: false,
  }, {
    component: 'a',
    target: '_blank',
    href: 'https://bitpreco.com/#faq',
    primary: 'screens.faq.title',
    icon: <HelpOutlined />,
    allowDemoAccount: false,
  }, {
    component: 'a',
    target: '_blank',
    href: 'https://bity.becompliance.com/canal-etica/canal-denuncias',
    primary: 'screens.reportingChannel.title',
    icon: <AnnouncementOutlined />,
    allowDemoAccount: false,
  },{
    component: 'a',
    target: '_blank',
    href: 'https://bitpreco.com/termos',
    primary: 'screens.terms.title',
    icon: <DescriptionOutlined />,
    allowDemoAccount: true,
  }, {
    primary: 'screens.contact.title',
    icon: <MailOutlined />,
    href: `mailto:${SUPPORT_MAIL}`,
    component: 'a',
    divider: true,
    allowDemoAccount: true,
  }, {
    primary: 'screens.logout.title',
    onClick: () => store.dispatch(endSession()),
    scene: 'logout',
    icon: <ExitToApp />,
    allowDemoAccount: true,
  },
];


const navItems = [
  {
    primary: 'expressTrade',
    to: '/express-trade/buy',
    scene: 'express-trade',
    subRoutes: ['buy', 'sell'],
    icon: <FlashOn />,
  },
  {
    primary: 'trade',
    to: '/trade',
    scene: 'trade',
    drawerNavigation: true,
    icon: <LocalAtm />,
  },
  {
    primary: 'cryptoConverter',
    icon: 'CryptoConverterIcon',
    tooltip: { placement: 'right' },
    to: '/express-trade/converter',
    scene: 'express-trade/converter',
    subRoutes: ['converter'],
  },
  {
    primary: 'extract',
    icon: <Receipt />,
    tooltip: { placement: 'right' },
    ...getVariantAvailableFeature('extract'),
    blockDemoAccount: true,
  },
  {
    primary: 'bankTransfers',
    icon: <AccountBalance />,
    tooltip: { placement: 'right' },
    ...getVariantAvailableFeature('bankTransfers', 'deposit'),
    blockDemoAccount: true,
  },
  {
    primary: 'sendReceiveCoins',
    icon: <SwapHoriz />,
    tooltip: { placement: 'right' },
    ...getVariantAvailableFeature('sendReceiveCoins', 'send'),
    blockDemoAccount: true,
  },
  {
    primary: 'card',
    icon: <CreditCard />,
    tooltip: { placement: 'right' },
    ...getVariantAvailableFeature('card', getFirstCardAvailable()),
    blockDemoAccount: true,
  },
  {
    primary: 'mgm',
    icon: 'MgmIcon',
    tooltip: { placement: 'right' },
    ...getVariantAvailableFeature('mgm', getFirstCardAvailable()),
    blockDemoAccount: true,
  },
  {
    primary: 'bix',
    icon: 'BixIcon',
    tooltip: { placement: 'right' },
    ...getVariantAvailableFeature('bix'),
    blockDemoAccount: true,
  },
  {
    primary: 'terms',
    icon: <DescriptionOutlined />,
    href: 'https://bitpreco.com/termos',
    target: '_blank',
    component: 'a',
    drawerNavigation: false,
  },
  {
    primary: 'profile',
    bottonNavigation: true,
    drawerNavigation: false,
    hidden: {
      smDown: true,
    },
    to: '/profile',
    scene: 'profile',
    icon: <AccountBalanceWallet />,
    blockDemoAccount: true,
  },
  {
    primary: 'support',
    bottonNavigation: true,
    drawerNavigation: false,
    to: '/chat',
    hidden: {
      smDown: true,
    },
    icon: <ContactSupport />,
  },
  {
    primary: 'logout',
    onClick: () => store.dispatch(endSession()),
    name: 'navItems_logout',
    scene: 'logout',
    icon: <ExitToApp />,
  },
];

const filterItem = (item, conditionalData) => {
  switch (item.primary) {
    case 'trade':
      return conditionalData.allowedToken;
    case 'cryptoConverter':
      return conditionalData.showCryptoConverter;
    default:
      return true;
  }
}

const getNavItems = (conditionalData) => {
  const { isDemoAccount, cards, } = conditionalData;
  if (isDemoAccount){
    return navItems.filter(item => filterItem(item, conditionalData)).map(item=>{
      if (item.blockDemoAccount)
        return {...item, ...getVariantAvailableFeature(item.primary, '', isDemoAccount), to: ''}
      if(item.primary === 'logout')
        return {...item, primary: 'loginRegister'}
      else{
        return item;
      }
    })
  }
  return navItems.filter(item => filterItem(item, conditionalData)).map(item=>{
    if (item.primary === 'card'){    
      return {...item, ...getVariantAvailableFeature(item.primary, getFirstCardAvailable(),false, cards)}}
    else if (item.primary === 'mgm'){
      return {...item, ...getVariantAvailableFeature(item.primary, checkUserAcceptMgmTerm())}
    }
    else{
      return item;
    }
  })

}

const getAppBarMenuItems = isDemoAccount =>{
  if (isDemoAccount){
    return appBarMenuItems.filter(item => item.allowDemoAccount);
  }
  return appBarMenuItems;
}

export default appBarMenuItems;

export { navItems, getNavItems, getAppBarMenuItems };
