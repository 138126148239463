import React, { useState, useEffect } from "react";
import { compose, pure, withState, lifecycle } from "recompose";
import Account from "./Account";
import Security from "./Security";
import OperationalLimits from "./OperationalLimits";
import TabPage from "../../components/PageLayouts/TabPage";
import { VARIANT } from "../../config";
import { fetchLimitsRequested } from "../../redux/actions";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import {styles} from './styles';

const EXCHANGE = VARIANT === "EXCHANGE";

const mapDispatchToProps = (dispatch) => ({
  fetchLimits: () => dispatch(fetchLimitsRequested()),
});

const Profile = (props) => {
  const { t, classes } = props;

  const [actualTab, setActualTab] = useState("");

  useEffect(() => {
    setActualTab(props.match.params.subRoute);
  });

  if (EXCHANGE) {
    return (
      <div className="profile">
        <div className={classes.formContainer}>
          <TabPage customStyles={{ containerStyle: { height: "100%" } }}>
            <Account route="account" label={t("account.title")} />
            <OperationalLimits
              route="operationalLimits"
              label={t("operationalLimits.title")}
            />
            <Security
              route="security"
              label={t("security.title")}
              actualTab={actualTab}
            />
          </TabPage>
        </div>
      </div>
    );
  }
  return <Account route="account" label={t("account.title")} />;
};

const enhance = compose(
  pure,
  withStyles(styles),
  withState("tabIndex", "setTabIndex", 0),
  connect(null, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.fetchLimits();
    },
  })
);

export default enhance(Profile);
