import React from "react";

import { Button, Grid, Typography } from "@material-ui/core";
import { Security } from "@material-ui/icons";
import MessageCard from "../../../../components/Cards/MessageCard";

const PinFormStep1 = (props) => {
  const { t, onTap, classes, stepData } = props;
  return (
    <Grid direction="column" alignItems="center" container>
      <Security classes={{ root: classes.educativeStepIcon }} fontSize={48} />
      <Typography
        className={classes.educativeTitle}
        align="center"
        component={"h2"}
        dangerouslySetInnerHTML={{ __html: t(stepData.title) }}
        variant="display1"
      />
      <Typography
        align="center"
        component={"p"}
        variant="subheading"
        dangerouslySetInnerHTML={{ __html: t(stepData.description) }}
      />
      <Grid
        container
        direction="column"
        spacing={8}
        className={classes.educativeTipsContainer}
      >
        {stepData.tips.map((tip, index) => (
          <Grid item key={`tip-${index}`}>
            <MessageCard type="info" content={t(tip)} />
          </Grid>
        ))}
      </Grid>

      <Button
        className={classes.submitButton}
        color="primary"
        variant="raised"
        onClick={onTap}
      >
        {t("common.toContinue")}
      </Button>
    </Grid>
  );
};

export default PinFormStep1;
