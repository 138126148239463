import { compose } from "recompose";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Slide,
  Dialog,
  DialogTitle,
  Hidden,
  DialogContent,
  Typography,
  withStyles,
  ButtonBase,
} from "@material-ui/core";
import {
  addAsideDialog,
  closeAsideDialog,
  addSnack,
  getPixAccount,
} from "../../../redux/actions";
import React from "react";
import { translate } from "react-i18next";
import QRCode from "qrcode.react";
import classNames from "classnames";
import DangerouslySetInnerHTML from "../../../components/DangerouslySetInnerHTML";
import StoreLinks from "../../../components/Buttons/StoreLinks";
import MissionInfo from "../../../pages/MGM/Cashback/components/MissionInfo";
import {
  AttachMoneyRounded,
  GroupAddOutlined,
  CloseOutlined,
  OpenInNew,
} from "@material-ui/icons";
import { conditionals } from "../../../pages/MGM/conditionals";
import { FIREBASE_MGM_INFO } from "../../../config";
import { styles } from "./styles";
import KnowBixDialog from "./components/knowBix";

function Transition(props) {
  const windowWidth = window.innerWidth;
  return (
    <Slide direction={`${windowWidth}` > 600 ? "left" : "up"} {...props} />
  );
}

const dialogContentComponents = {
  cardSell: ({ t, classes, title }) => (
    <>
      <div className={classes.sectionContainer}>
        <div>
          <h2 className={classes.dialogTitleContent}>
            {title ? t(title) : t("screens.cards.dialog.secondTitle")}
          </h2>
        </div>

        <Hidden xsDown>
          <DangerouslySetInnerHTML
            className={classNames(classes.infoDialog, classes.textMuted)}
            title={t("screens.cards.dialog.secondContent")}
          />
          <div className={classes.qrCode}>
            <QRCode size={120} value={"https://link.bity.com.br/app"} />
          </div>
        </Hidden>

        <Hidden smUp>
          <DangerouslySetInnerHTML
            className={classNames(classes.infoDialog, classes.textMuted)}
            title={t("screens.cards.dialog.secondContentMobile")}
          />
          <DangerouslySetInnerHTML
            className={classNames(classes.infoDialog, classes.textMuted)}
            title={t("screens.cards.dialog.redirect")}
          />
        </Hidden>
      </div>

      <div
        className={classNames(
          classes.flexColumn,
          classes.center,
          classes.textMuted,
          classes.gap45
        )}
      >
        <div>
          <Hidden xsDown>
            <p>{t("screens.cards.dialog.links")}</p>
          </Hidden>
          <StoreLinks></StoreLinks>
        </div>
        <a
          className={classNames(
            classes.textMuted,
            classes.cardLanding,
            classes.disclamer
          )}
          href="https://bity.com.br/cartao"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("screens.cards.dialog.redirect")}
          <OpenInNew />
        </a>
      </div>
    </>
  ),
  knowBix: ({ t, classes, title, keyPixAccount, addSnackBar, getPixAccount }) => {
    return <KnowBixDialog t={t} classes={classes} title={title} keyPixAccount={keyPixAccount} addSnackBar={addSnackBar} getPixAccount={getPixAccount}/>
  },

  cashbackMissions: ({ t, classes, renderComponentProps, closeDialog }) => {
    const { userData, redirectToMgm } = renderComponentProps;
    const { missionsData, referralsData, cardLimits } = userData;
    const {
      getTotalPurchasesLastThirtyDays,
      getBuyMoreToEarnCashback,
      getTotalPurchasesLastNinetyDays,
      getMissingReferralsToEarnCashback,
    } = conditionals;
    let {
      nextMgmLevel,
      nextCardLevel,
      missingCard,
      missingMgm,
      hasFirstMonthBonus,
    } = missionsData;
    const hasCashbackBonusMarketingEvent =
      userData.cashbackInfo.cashback_composition.marketing_event !== null &&
      userData.cashbackInfo.cashback_composition.marketing_event !== 0;

    const cardVolumeTitle = hasFirstMonthBonus
      ? t("screens.mgm.cashback.dailyCashback.modalCardMissionFirstMonthBonus")
      : nextCardLevel === null
      ? t("screens.mgm.cashback.dailyCashback.modalCardMissionCompleteTitle")
      : t("screens.mgm.cashback.dailyCashback.cardCta", {
          value: missingCard,
        });

    const mgmTitle =
      nextMgmLevel === null
        ? t("screens.mgm.cashback.dailyCashback.modalReferMissionCompleteTitle")
        : missingMgm > 1
        ? t("screens.mgm.cashback.dailyCashback.referCtaPlural", {
            count: missingMgm,
          })
        : t("screens.mgm.cashback.dailyCashback.referCtaSingular");

    const mgmCollapseExtra = () => {
      return (
        <ButtonBase
          onClick={() => {
            closeDialog();
            redirectToMgm();
          }}
          className={classes.bitybankButton}
        >
          {t("screens.mgm.cashback.dailyCashback.referNow")}
        </ButtonBase>
      );
    };

    return (
      <>
        <div
          className={classNames(
            classes.sectionContainer,
            classes.maxWidthMissionsContent
          )}
        >
          <div>
            <Typography
              component={"h2"}
              align={"left"}
              className={classes.titleBig}
            >
              {t("screens.mgm.cashback.dailyCashback.missions")}
            </Typography>
          </div>

          <DangerouslySetInnerHTML
            className={classNames(classes.textContent, classes.textMuted)}
            title={t(
              "screens.mgm.cashback.dailyCashback.missionsDialogContent"
            )}
          />

          {!hasCashbackBonusMarketingEvent && (
            <MissionInfo
              cardIcon={AttachMoneyRounded}
              nextLevelValue={nextCardLevel}
              headerTitle={cardVolumeTitle}
              headerValueLabel={
                nextMgmLevel
                  ? t("screens.mgm.cashback.dailyCashback.ofCashback")
                  : null
              }
              collapseFirstCardContent={getTotalPurchasesLastThirtyDays(
                cardLimits,
                t
              )}
              collapseSecondCardContent={getBuyMoreToEarnCashback(
                hasFirstMonthBonus,
                missionsData,
                t
              )}
            />
          )}
          <MissionInfo
            cardIcon={GroupAddOutlined}
            nextLevelValue={nextMgmLevel}
            headerTitle={mgmTitle}
            headerValueLabel={
              nextMgmLevel
                ? t(
                    "screens.mgm.cashback.dailyCashback.ofCashbackAndMoreInYourAccount",
                    { value: FIREBASE_MGM_INFO.winValuePerIndication }
                  )
                : null
            }
            collapseFirstCardContent={getTotalPurchasesLastNinetyDays(
              referralsData,
              t
            )}
            collapseSecondCardContent={getMissingReferralsToEarnCashback(
              missionsData,
              FIREBASE_MGM_INFO.winValuePerIndication,
              t
            )}
            collapseExtraContent={mgmCollapseExtra}
          />
        </div>

        <div
          className={classNames(
            classes.flexColumn,
            classes.center,
            classes.textMuted,
            classes.gap10,
            classes.maxWidthMissionsContent
          )}
        >
          <p>{t("screens.mgm.cashback.totalCashback.knowMoreThroughTheApp")}</p>

          <StoreLinks width={"100%"} flexDirection={"column"}></StoreLinks>
        </div>
      </>
    );
  },
};

const AsideDialog = (props) => {
  const {
    closeDialog,
    t,
    classes,
    addSnackBar,
    getPixAccount,
    keyPixAccount: keyPixAccount,
    dialog: { title, renderComponent, open, renderComponentProps },
  } = props;
  const ContentComponent = renderComponent
    ? dialogContentComponents[renderComponent]
    : null;
  const modalClass =
    renderComponent === "cashbackMissions"
      ? classNames(classes.dialogPaper, classes.bgFullWhite)
      : classNames(classes.dialogPaper);
  let modalContentClass; 
  if (renderComponent === "cashbackMissions"){
    modalContentClass = classNames(classes.cardMissionsDialogContent);
  } else if (renderComponent === "knowBix") {
    modalContentClass = classNames(classes.bixDialogContent);
  } else {
    modalContentClass = classNames(classes.dialogContent);
  }
  return (
    <Dialog
      open={open}
      color={"primary"}
      TransitionComponent={Transition}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      className={classes.dialogContainer}
      //   maxWidth={'xs'}
      classes={{ paper: modalClass }}
    >
      <DialogTitle
        align={"center"}
        id="alert-dialog-slide-title"
        className={classNames(classes.flexColumn, classes.dialogTitle)}
      >
        <button className={classes.closeButton}>
          <CloseOutlined
            className={classes.closeIcon}
            onClick={() => closeDialog()}
          ></CloseOutlined>
        </button>
      </DialogTitle>
      <DialogContent classes={{ root: modalContentClass }}>
        {renderComponent && (
          <ContentComponent
            {...{ t, classes, renderComponentProps, closeDialog, title, keyPixAccount, addSnackBar, getPixAccount}}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    keyPixAccount: state.user.account,
    dialog: state.credentials.cardDialog,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addSnackBar: (message) => dispatch(addSnack(message)),
  getPixAccount: () => dispatch(getPixAccount()),
  closeDialog: () => dispatch(closeAsideDialog()),
  openDialog: () => dispatch(addAsideDialog()),
});

export default compose(
  translate(),
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(AsideDialog);
