
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

const slideRenderer = children => params => {
  const { key } = params;

  return children[key];
};

const SwipeableTabs = ({ children, customStyles, ...rest }) => {
  return (
    <VirtualizeSwipeableViews
      containerStyle={ customStyles?.containerStyle}
      animateHeight
      slideRenderer={slideRenderer(children)}
      {...rest}
    />
  );
};

export default SwipeableTabs;
