import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import Form from '../../../components/Forms/Form';
import EmailField from '../../../components/Forms/EmailField/EmailField';
import TaxIDField from '../../../components/Fields/TaxIDField';
import SubmitButton from '../../../components/Forms/SubmitButton';
import FieldRow from '../../../components/Forms/FieldRow';

const ResetPasswordForm = props => {
  const {
    handleSubmit, submitting, invalid, t, submitSucceeded, classes, reset,
  } = props;
  const resetForm = () =>{
    reset();
  }
  return (
    <Form {...{ handleSubmit }}>
      <FieldRow>
        <EmailField
          autoFocus
          margin="dense"
          required
          autoComplete="email"
          name="email"
        />
      </FieldRow>
      <FieldRow>
        <TaxIDField
          autoFocus
          margin="dense"
          required
          name="taxId"
        />
      </FieldRow>
      <FieldRow>
        <SubmitButton
          submitText={t('resetPassword.sendResetMail')}
          disabled={(submitting || invalid)}
        />
      </FieldRow>
      <FieldRow>
        <Button
          color="secondary"
          variant="raised"
          component={Link}
          to="/login"
        >
          {t('screens.login.signIn')}
        </Button>
      </FieldRow>
    </Form>
  );
};

const styles = theme => ({
  submitSucceeded: {
    '&:disabled': {
      backgroundColor: theme.colors.green.main,
      color: theme.palette.common.white,
    },
  },
});

export default withStyles(styles)(ResetPasswordForm);
