import { DEMO_ACCOUNT_EMAIL, CNPJ_BITYPRECO } from "../config";

export const isDemoAccount = (userEmail) => {
  return userEmail === DEMO_ACCOUNT_EMAIL;
};

export const checkIsRobotAccount = (taxId) => {
  if (typeof taxId !== "string") {
    taxId = String(taxId);
  }
  return taxId === CNPJ_BITYPRECO;
};
