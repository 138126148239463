import privateService from "../privateService";

const createPin = async (form) => {
  const options = {
    cmd: "set_pin",
    method: "POST",
  };

  try {
  const content = await privateService(options, form);
  return content;
  }
  catch (e) {
    console.log('requestPinCreate', e);
    throw e;
  }
};

export default createPin;