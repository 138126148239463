import React from "react";
import PropTypes from "prop-types";

import { compose, pure, renderNothing, branch } from "recompose";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import serializeBank from "../../../utils/serializeBankAccount";
import { getWindowSize } from "../../../utils/getWindowSize";
import { useState, useEffect } from "react";

import { SUPPORT_FAQ_CENTER_URL } from "../../../utils/constants";
import {
  Button,
  ButtonBase,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import CurrencyIcon from "../../../components/CurrencyIcon";
import tickerSuccess from "../../../assets/ticker-sucess.svg";
import { closeDialog, makeDialogChoice } from "../../../redux/layout/actions";
import Typography from "@material-ui/core/Typography";
import {
  BLOG_TRANSFER_SECURITY_POST,
  APP_STORE_ADDRESS,
  GOOGLE_PLAY_ADDRESS,
} from "../../../config";
import AppStore from "../../../assets/app-store.png";
import Login from "../../../pages/User/Login";
import { toFixedNoRound } from "../../../utils/toFixedNoRound";
import DangerouslySetInnerHTML from "../../../components/DangerouslySetInnerHTML";
import { RecordVoiceOver, MonetizationOnOutlined } from "@material-ui/icons";
import classNames from "classnames";
import SvgIcon from "../../../components/SvgIcon/SvgIcon";
import { styles } from "./styles";
import { PinRequiredMessage } from "./components/PinRequiredMessage";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

const mapStateToProps = (state) => {
  return {
    dialog: state.layout.dialog,
  };
};

const dialogContentComponents = {
  bankDepositWarning: ({ t, classes }) => (
    <React.Fragment>
      <div className={classes.warningContainer}>
        <Typography className={classes.warningText} variant="body2">
          {`• ${t("info.depositSameCPFWarning")}`}
        </Typography>
        <Typography className={classes.warningText} variant="body2">
          {`• ${t("info.depositSameCNPJWarning")}`}
        </Typography>
        <Typography className={classes.warningText} variant="body2">
          {`• ${t("info.newDepositWarning")}`}
        </Typography>
        <Typography className={classes.warningText} variant="body2">
          {`• ${t("info.firstDepositWarning")}`}
        </Typography>
        <Typography className={classes.warningText} variant="body2">
          {`• ${t("info.titularityAccount")}`}
        </Typography>
        <Typography className={classes.warningText} variant="body2">
          {`• ${t("info.depositAfter17Warning")}`}
        </Typography>
        <Typography className={classes.warningText} variant="body2">
          {`• ${t("info.depositMadeFromAnotherCpfWarning")}`}
        </Typography>
      </div>
    </React.Fragment>
  ),
  depositPixWarning: ({ t, classes }) => (
    <div className={classes.warningContainer}>
      <Typography className={classes.warningText} variant="body2">{`• ${t(
        "info.pixDepositSameCPFWarning"
      )}`}</Typography>
      <Typography className={classes.warningText} variant="body2">{`• ${t(
        "info.pixDepositSameCNPJWarning"
      )}`}</Typography>
      <Typography className={classes.warningText} variant="body2">{`• ${t(
        "info.firstDepositWarning"
      )}`}</Typography>
      <Typography className={classes.warningText} variant="body2">{`• ${t(
        "info.titularityAccount"
      )}`}</Typography>
    </div>
  ),
  bankWithdrawalWarning: ({ t, classes, isRobotAccount, ...restProps }) => {
    const [width, setWidth] = useState(getWindowSize().width);
    useEffect(() => {
      const handleResize = () => {
        setWidth(getWindowSize().width);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    return (
      <div className={classes.warningContainer}>
        <Typography className={classes.warningText} variant="body2">
          {`• ${t("info.withdrawalTimeLimitWarning")}`}
        </Typography>
        <Typography className={classes.warningText} variant="body2">
          {`• ${t("info.titularityDepositWarning")}`}
        </Typography>
        {!isRobotAccount && (
          <PinRequiredMessage classes={classes} width={width} t={t} />
        )}
      </div>
    );},
  bankWithdrawalSameTitularityWarning: ({ t, classes, isRobotAccount, ...restProps }) => {
    const [width, setWidth] = useState(getWindowSize().width);
    useEffect(() => {
      const handleResize = () => {
        setWidth(getWindowSize().width);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return (
      <div className={classes.warningContainer}>
        <Typography className={classes.warningText} variant="body2">
          {`• ${t("info.titularityDepositWarning")}`}
        </Typography>
        {!isRobotAccount && (
          <PinRequiredMessage classes={classes} width={width} t={t} />
        )}
      </div>
    );
  },
  pinIsRequiredToWithdrawalWarning: ({ t, classes, ...restProps }) => {
    const [width, setWidth] = useState(getWindowSize().width);

    useEffect(() => {
      const handleResize = () => {
        setWidth(getWindowSize().width);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return (
    <div className={classes.warningContainer}>
      <PinRequiredMessage classes={classes} width={width} t={t} />
    </div>
    );
  },
  fiatCBRLWithdrawalWarning: ({ t, classes, ...restProps }) => (
    <div className={classes.warningContainer}>
      <Typography className={classes.warningText} variant="body2">
        {`• ${t("info.fiatCBRLWithdrawalWarning", { bank: restProps.bank })}`}
      </Typography>
    </div>
  ),
  criptoStablecoinDepositWarning: ({ t, classes, ...restProps }) => (
    <div className={classes.warningContainer}>
      <Typography className={classes.warningTextBold} variant="body2">
        {`• ${t("info.infoDepositStableCoinTitle", {
          depositLimit: restProps.depositLimit,
          currency: restProps.currency,
        })}`}
      </Typography>
      <Typography className={classes.warningText} variant="body2">
        {t("info.infoDepositStableCoinBody")}
      </Typography>
    </div>
  ),
  newsComponent: ({ classes, img, desc, link }) => {
    const image = (
      <img src={img} aria-label={desc} width={"100%"} height={"100%"} />
    );
    return (
      <div>
        <DialogContentText className={classes.innerImg}>
          {link ? (
            <a href={link} target="_blank">
              {" "}
              {image}{" "}
            </a>
          ) : (
            image
          )}
        </DialogContentText>
      </div>
    );
  },
  apiTokenRequest: ({ t, classes }) => (
    <div className={classes.warningContainer}>
      <Typography className={classes.warningText} variant="body2">
        {`• ${t("security.requestApiToken")}`}
      </Typography>
    </div>
  ),
  alreadyRegisteredTAXID: ({ t }) => (
    <div>
      <Typography variant="body2">{t("common.whatChoice")}</Typography>
    </div>
  ),
  directorMustHaveApprovedAccount: ({ t }) => (
    <div>
      <Typography variant="body2">
        {t("errorCompanyDirector.content")}
      </Typography>
    </div>
  ),
  alertDifferenceCopyWalletAddress: ({ t, classes, ...restProps }) => {
    const address = restProps.walletAddress;
    const BOLD_RANGE = 6;
    const MID = address.length / 2;
    const addressBoldStart = address.substring(0, BOLD_RANGE);
    const addressNormalStart = address.substring(BOLD_RANGE, MID - BOLD_RANGE);
    const addressBoldMid = address.substring(MID - BOLD_RANGE, MID);
    const addressNormalEnd = address.substring(
      MID,
      address.length - BOLD_RANGE
    );
    const addressBoldEnd = address.substring(
      address.length - BOLD_RANGE,
      address.length
    );

    return (
      <div className={classes.warningContainer}>
        <Typography className={classes.warningText} variant="body2">
          {t("common.differentAddressWarning")}
        </Typography>
        <Typography className={classes.longText}>
          {t("common.yourAddress")}
          <strong>{addressBoldStart}</strong>
          {addressNormalStart}
          {addressBoldMid}
          {addressNormalEnd}
          <strong>{addressBoldEnd}</strong>
        </Typography>
        <Typography className={classes.link}>
          {t("common.toUnderstandMessage")}{" "}
          <a target="_blank" href={BLOG_TRANSFER_SECURITY_POST}>
            {t("common.clickHereLink")}
          </a>
          .
        </Typography>
      </div>
    );
  },
  alertBSCSelected: ({ t, classes }) => (
    <div className={classes.warningContainer}>
      <Typography className={classes.warningText} variant="body2">
        {`• ${t("info.infoNetworkBSCSelectedBody")}`}
      </Typography>
    </div>
  ),
  alertLowPriorityFeeSelected: ({ t, classes }) => (
    <div className={classes.warningContainer}>
      <Typography className={classes.warningText} variant="body2">
        {`• ${t("info.alertLowPriorityFeeSelectedBody")}`}
      </Typography>
    </div>
  ),
  registerAccountInProgress: ({ t, classes }) => (
    <div className={classes.flexContainer}>
      <Typography variant="body2">
        {`${t("screens.register.verifyingData")}`}
      </Typography>
      <Typography style={{ marginLeft: 5 }}>
        <CircularProgress size={20} />
      </Typography>
    </div>
  ),
  congratulationsOnEconomy: ({ t, classes, ...restProps }) => (
    <div>
      <Grid item xs={12} className={classes.congratulationsDialogCenter}>
        <CurrencyIcon
          classIcon={classes.icon}
          market={restProps.formik.props.market}
        />
        <Grid item xs={7}>
          <Typography
            variant="body2"
            className={classes.congratulationsDialogText}
            style={{
              textAlign: "left",
              lineHeight: "1.6rem",
              fontSize: "30px",
            }}
          >
            {`${t("transactions.congratulationOnEconomy.coin", {
              coin: restProps.formik.props.market.replace("-BRL", ""),
            })}`}{" "}
            {`${t("transactions.congratulationOnEconomy.valueCrypto", {
              value: restProps.values.amount.toFixed(8),
            })}`}
          </Typography>
          <Typography
            variant="body2"
            style={{ textAlign: "left", color: "#6c757d" }}
          >
            {`${t("transactions.addWallet")}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.congratulationsDialogCenter}>
        <Grid
          item
          xs={10}
          justify="center"
          style={{ marginTop: "25px", textAlign: "center" }}
        >
          <Typography
            variant="body2"
            color="secondary"
            className={classes.congratulationsDialogText}
            style={{ fontSize: "25px" }}
          >
            {`${t("transactions.congratulationOnEconomy.congratulations")}`}
          </Typography>
          <DangerouslySetInnerHTML
            variant="body2"
            className={classes.congratulationsDialogInfo}
            title={t("transactions.congratulationOnEconomy.economy", {
              economy: toFixedNoRound(restProps.valueEconomy, 2)
                .toString()
                .replace(".", ","),
            })}
            style={{ marginBottom: "15px", fontSize: "25px" }}
          />

          <DangerouslySetInnerHTML
            variant="body2"
            className={classes.congratulationsDialogExplanatory}
            title={t("transactions.congratulationOnEconomy.warranty", {
              economy: toFixedNoRound(restProps.valueEconomy, 2)
                .toString()
                .replace(".", ","),
              averageOrders: restProps.lastOrders.length,
            })}
          />
        </Grid>
      </Grid>
    </div>
  ),
  withdrawalProgressDialog: ({ t, classes }) => (
    <div className={classes.flexContainer}>
      <Typography variant="body2">{`${t("common.processing")}`}</Typography>
      <Typography style={{ marginLeft: 8 }}>
        <CircularProgress size={20} />
      </Typography>
    </div>
  ),
  confirmAccountWithdrawal: ({ t, classes, ...props }) => {
    const selectedBank = serializeBank({
      address: props.address,
    });
    const userNameHolder = props.userName;
    return (
      <React.Fragment>
        <div
          className={classes.warningContainer}
          style={{ marginTop: "0", padding: "16px 20px" }}
        >
          <Typography
            align="center"
            style={{ margin: "0" }}
            className={classes.warningText}
            variant="body2"
          >
            {`${t("transfers.withdrawal.confirmBank")}`}
          </Typography>
          <Grid
            className={classes.confirmAccountContainer}
            alignItems="flex-start"
            container
            justify="center"
          >
            <Grid
              className={classes.confirmAccountContent}
              align="left"
              direction="column"
              item
              xl={4}
            >
              <Typography variant="body2">
                {t("transactions.myBank")}:
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {selectedBank.bank} - {selectedBank.bankName}
              </Typography>
            </Grid>
            <Grid
              className={classes.confirmAccountContent}
              align="left"
              direction="column"
              item
              xl={3}
            >
              <Typography variant="body2">
                {t("forms.agency.title")}:
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {selectedBank.agency}
              </Typography>
            </Grid>
            <Grid
              className={classes.confirmAccountContent}
              align="left"
              direction="column"
              item
              xl={3}
            >
              <Typography variant="body2">
                {t("forms.account.title")}:
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {selectedBank.account}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            className={classes.confirmAccountContainer}
            alignItems="flex-start"
            container
            style={{ justifyContent: "flex-start", margin: "0px" }}
          >
            <Grid
              className={classes.confirmAccountContent}
              align="left"
              direction="column"
              item
              xl={3}
            >
              <Typography variant="body2">
                {t("forms.account.holder")}:
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {userNameHolder}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  },
  onValidationTokenFail: ({ t, classes, ...restProps }) => (
    <div className={classes.flexContainer}>
      <Typography variant="body2">
        {`${t("forms.validationTokenDialog.token.invalidToken", {
          remaining: restProps.remaining,
        })}`}
      </Typography>
    </div>
  ),
  onValidationPINFail: ({ t, classes, ...restProps }) => (
    <div className={classes.flexContainer}>
      <Typography variant="body2">
        {`${t("forms.cards.invalidPIN", { remaining: restProps.remaining })}`}
      </Typography>
    </div>
  ),
  warnAvailableDownloadApp: ({ t, classes }) => (
    <div>
      <Typography variant="body2">
        {t("info.warnAvailableDownloadApp")}
      </Typography>
      <Typography style={{ marginLeft: 8 }}>
        <a target="_blank" href={GOOGLE_PLAY_ADDRESS}>
          <img
            width={200}
            alt="Disponível no Google Play"
            src="https://play.google.com/intl/pt-BR/badges/static/images/badges/pt_badge_web_generic.png"
          />
        </a>
        <a target="_blank" href={APP_STORE_ADDRESS}>
          <img width={180} style={{ marginLeft: 10 }} src={AppStore} alt="" />
        </a>
      </Typography>
    </div>
  ),
  renewConnectionAuthToken: ({ t, classes, ...restProps }) => (
    <Login {...restProps} />
  ),
  acceptMgmTerms: ({ t, classes, onAccept }) => (
    <div>
      <DangerouslySetInnerHTML
        className={classes.titleDefault}
        title={`${t("screens.mgm.acceptTermsDialog.title")}`}
      />
      <div
        className={classNames(
          classes.flexColumn,
          classes.gapDefault,
          classes.marginTopDefault
        )}
      >
        <RecordVoiceOver classes={{ root: classes.mgmTermsDialogIcon }} />
        <DangerouslySetInnerHTML
          className={classes.boldSubtitle}
          title={`${t("screens.mgm.acceptTermsDialog.phrase1")}`}
        />
      </div>
      <div
        className={classNames(
          classes.flexColumn,
          classes.marginTopDefault,
          classes.gapDefault
        )}
      >
        <MonetizationOnOutlined
          classes={{ root: classes.mgmTermsDialogIcon }}
        />
        <DangerouslySetInnerHTML
          className={classes.boldSubtitle}
          title={`${t("screens.mgm.acceptTermsDialog.phrase2")}`}
        />
      </div>
      <div
        className={classNames(
          classes.flexColumn,
          classes.marginTopDefault,
          classes.gapDefault
        )}
      >
        <SvgIcon icon={"MgmIcon"} color={"#F7B733"} />
        <DangerouslySetInnerHTML
          className={classes.boldSubtitle}
          title={`${t("screens.mgm.acceptTermsDialog.phrase3")}`}
        />
      </div>
      <div className={classes.flexContainer}>
        <ButtonBase
          onClick={onAccept}
          className={classNames(
            classes.bitybankButton,
            classes.marginTopDefault
          )}
        >
          {t("screens.mgm.acceptTermsDialog.accept")}
        </ButtonBase>
      </div>
      <Typography
        className={classNames(classes.linkSubtitle, classes.marginTop10)}
      >
        {t("screens.mgm.acceptTermsDialog.disclaimer")}
        <a
          href="https://www.bity.com.br/assets/doc/Regulamento-Indique-e-Ganhe.pdf"
          target={"_blank"}
        >
          {t("screens.mgm.acceptTermsDialog.terms")}
        </a>
      </Typography>
    </div>
  ),
  supportChat: ({ t, classes, ...restProps }) => (
    <div>
      <Typography
        component={"p"}
        className={classNames(classes.linkSubtitle, classes.supportLink)}
        variant="body2"
      >
        {t("errorBoundary.doYouNeedHelp")}{" "}
        <a href={SUPPORT_FAQ_CENTER_URL} target="_blank">
          {t("errorBoundary.contactSupport")}
        </a>
      </Typography>
    </div>
  ),
  hasNoWithdrawalOrDepositWarning: ({ t, classes, ...restProps }) => (
    <div className={classNames(classes.warningContainer, classes.marginTop0)}>
      <Typography
        className={classes.warningText}
        variant="body2"
        dangerouslySetInnerHTML={{
          __html: `• ${t("info.hasNoWithdrawalOrDepositBold", {
            token: restProps.currencyName,
          })}`,
        }}
      ></Typography>
    </div>
  ),
};

const mapDispatchToProps = (dispatch) => ({
  makeChoice: (action) => dispatch(makeDialogChoice(action)),
  close: () => dispatch(closeDialog()),
});

const enhance = compose(
  pure,
  withStyles(styles),
  translate(),
  connect(mapStateToProps, mapDispatchToProps),
  branch((props) => !props.dialog.open, renderNothing)
);

const SlideDialog = (props) => {
  const {
    makeChoice,
    close,
    dialog: {
      open,
      title,
      centerTitle = false,
      content,
      availableChoices = [],
      disableBackdropClick = false,
      disableEscapeKeyDown = false,
      renderComponent = null,
      componentProps,
    },
    classes,
    t,
  } = props;

  const ContentComponent = renderComponent
    ? dialogContentComponents[renderComponent]
    : null;

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={close}
        disableBackdropClick={disableBackdropClick}
        disableEscapeKeyDown={disableEscapeKeyDown}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        classes={
          componentProps &&
          componentProps.paperClass
            ? { paper: classes[componentProps.paperClass] }
            : undefined
        }
        className={
          renderComponent == "congratulationsOnEconomy"
            ? classes.congratulationsDialog
            : renderComponent
            ? classes[renderComponent]
            : ""
        }
      >
        <DialogTitle
          align={centerTitle ? "center" : "inherit"}
          id="alert-dialog-slide-title"
          className={
            renderComponent == "congratulationsOnEconomy"
              ? classes.congratulationsDialogTitle
              : ""
          }
        >
          <Grid
            xs={12}
            className={
              renderComponent == "congratulationsOnEconomy"
                ? classes.congratulationsDialogCenter
                : ""
            }
          >
            {renderComponent == "congratulationsOnEconomy" && (
              <img
                style={{ marginRight: "10px" }}
                src={tickerSuccess}
                width={70}
                height={70}
                alt=""
              />
            )}
            <Typography
              className={
                renderComponent == "congratulationsOnEconomy"
                  ? classes.congratulationsDialogTitleText
                  : ""
              }
              variant="body2"
            >
              <DangerouslySetInnerHTML title={t(title)} />
            </Typography>
          </Grid>
        </DialogTitle>
        {content && (
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {content}
            </DialogContentText>
          </DialogContent>
        )}
        {renderComponent && (
          <DialogContent
            className={
              renderComponent == "congratulationsOnEconomy"
                ? classes.congratulationsDialogContent
                : ""
            }
          >
            <ContentComponent {...{ t, classes }} {...componentProps} />
          </DialogContent>
        )}
        <DialogActions
          className={
            renderComponent == "congratulationsOnEconomy"
              ? classes.congratulationsDialogCenter
              : ""
          }
        >
          {availableChoices.map(
            ({ label, actionToTake = "", onClick, ...rest }) => {
              return (
                <Button
                  className={
                    renderComponent == "congratulationsOnEconomy"
                      ? classes.congratulationsDialogButton
                      : ""
                  }
                  key={`${label}_${actionToTake}`}
                  onClick={() => {
                    makeChoice({ actionToTake });
                    onClick && onClick();
                  }}
                  color="primary"
                  {...rest}
                >
                  {t(label)}
                </Button>
              );
            }
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
  // }
};

SlideDialog.defaultProps = {
  dialog: {
    open: false,
    title: "",
    content: "",
    availableChoices: [],
  },
};

SlideDialog.propTypes = {
  t: PropTypes.func.isRequired,
  makeChoice: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  dialog: PropTypes.shape({
    open: PropTypes.bool,
    title: PropTypes.node,
    content: PropTypes.node,
    availableChoices: PropTypes.array,
  }).isRequired,
};

export default enhance(SlideDialog);
