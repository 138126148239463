import React from 'react';
import { compose, pure } from 'recompose';
import { translate } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import ProfileToolbar from '../components/ProfileToolbar';
import ResetPassword from './ResetPassword';
import ApiToken from './ApiToken';
import ExpandingOptions from './ExpandingOptions';

const styles = theme => ({
  itemsContainer: {
    padding: '12px 24px'
  },
  root: {
    flex: 1,
    paddingBottom: 100,
  },
  '@media screen and (min-width: 600px)': {
    list: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
    },
  },
  list: {
    flexDirection: 'row',
    marginBottom: '12px',
  },
  listItem: {
    height: '84px',
  },
  fieldItem: {
    padding: 0,
    ...theme.typography.body1,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
});

const Security = props => {
  const {
    classes, t, actualTab
  } = props;

  return (
    <div className={classes.root}>
      <ProfileToolbar title={t('security.credentials')} />
      <div className={classes.itemsContainer}>
          <Grid container className={classes.list} spacing={24}>
            <Grid className={classes.listItem} item sm={6} xs={12}>
            <ResetPassword/>
            </Grid>
            <Grid className={classes.listItem} item sm={6} xs={12}>
            <ApiToken />
            </Grid>
          </Grid>
          <ExpandingOptions actualTab={actualTab}/>
      </div>
    </div>
  );
};

const enhance = compose(
  withStyles(styles),
  translate(),
  pure,
);

export default enhance(Security);
