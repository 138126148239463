import { put, takeLatest, call, all } from "redux-saga/effects";
import {
  FETCH_CURRENCIES_REQUESTED,
  FETCH_MARKET_REQUESTED,
  UPDATE_CURRENCIES,
  UPDATE_MARKET,
  HANDLE_CURRENCIES_CACHE,
  USE_CACHED_CURRENCIES,
} from "./actionTypes";
import { fetchService } from "../actions";
import { bityIndexedDB, bityIndexedDBStores } from "../../services/db";
import {
  setRevalidateCurrenciesDataTimeStamp,
  needToRevalidateCurrenciesData,
} from "../../utils/cookies";

function* FetchCurrencies() {
  try {
    yield call(() => bityIndexedDB.initDB());
    const currenciesStoreHasValues = yield call(() => bityIndexedDB.checkStoreHasValues(bityIndexedDBStores.currencies));
    const needToRevalidate = needToRevalidateCurrenciesData() || !currenciesStoreHasValues
    if (needToRevalidate) {
      yield put(
        fetchService({
          requestType: "fetchCurrencies",
          successAction: HANDLE_CURRENCIES_CACHE,
          failAction: USE_CACHED_CURRENCIES,
          paramns: {
            privateService: true,
            options: {
              cmd: "get_currencies",
              method: "POST",
            },
          },
        })
      );
    } else {
      yield put({
        type: USE_CACHED_CURRENCIES,
      });
    }
  } catch (e) {
    console.error(FETCH_CURRENCIES_REQUESTED, e);
  }
}

function* FetchMarket() {
  try {
    yield put(
      fetchService({
        requestType: "fetchMarket",
        successAction: UPDATE_MARKET,
        paramns: {
          privateService: true,
          options: {
            cmd: "get_markets",
            method: "POST",
          },
        },
      })
    );
  } catch (e) {
    console.error(FETCH_MARKET_REQUESTED, e);
  }
}

function* useCachedCurrencies(_) {
  try {
    const currenciesData = yield call(() =>
      bityIndexedDB.getStoreData(bityIndexedDBStores.currencies)
    );
    const currenciesObject = {};
    if (currenciesData && currenciesData.length > 0) {
      for (const currency of currenciesData) {
        currenciesObject[currency.symbol] = currency;
      }
      yield put({
        type: UPDATE_CURRENCIES,
        currencies: currenciesObject,
      });
    }
  } catch (e) {
    console.error(USE_CACHED_CURRENCIES, e);
  }
}

function* handleCurrenciesCache(action) {
  const { response } = action;
  try {
    delete response["success"];
    yield call(() => bityIndexedDB.clearStore(bityIndexedDBStores.currencies));
    
    const addDataCalls = Object.entries(response).map(([key, value]) =>
      call(() => bityIndexedDB.addData(bityIndexedDBStores.currencies, value))
    );
    yield all(addDataCalls);
    
    setRevalidateCurrenciesDataTimeStamp();
  } catch (e) {
    console.error(HANDLE_CURRENCIES_CACHE, e);
  }
  yield put({
    type: UPDATE_CURRENCIES,
    currencies: response,
  });
}

function* watchCurrencies() {
  yield takeLatest(FETCH_MARKET_REQUESTED, FetchMarket);
  yield takeLatest(FETCH_CURRENCIES_REQUESTED, FetchCurrencies);
  yield takeLatest(HANDLE_CURRENCIES_CACHE, handleCurrenciesCache);
  yield takeLatest(USE_CACHED_CURRENCIES, useCachedCurrencies);
}

export default watchCurrencies;
