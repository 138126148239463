export const styles = (theme) => ({
  formContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '0',
    },
  },
});
