import React from 'react'
import {useEffect, useState} from 'react';
import * as R from 'ramda'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import Typography from '@material-ui/core/Typography'
import CardHeader from '@material-ui/core/CardHeader'
import Avatar from '@material-ui/core/Avatar'
import Checkbox from '@material-ui/core/Checkbox'
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import isNilOrEmpty from '../../../utils/isNilOrEmpty';
import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import BankAccountForm from '../../../containers/BankAccountForm/index';
import FieldRow from '../../../components/Forms/FieldRow'
import Select from '../../../components/FormsV2/Select'
import MoneyInput from '../../../components/FormsV2/MoneyInput'
import TextInput from '../../../components/FormsV2/TextInput'
import LimitsIndicator from '../../../components/LimitsIndicator'
import FeesIndicator from '../../../components/FeesIndicator'
import SubmitButton from '../../../components/Forms/SubmitButton'
import PercentageSwitchButtons from '../../../components/FormsV2/PercentageSwitchButtons'
import NumberMaskFormatInput from '../../../components/Fields/NumberMaskFormatInput'

import ReceiveCoins from '../../SendReceiveCoins/ReceiveCoins'

import TransferFiatDetails from './TransferFiatDetails'

import serializeBankAccount from '../../../utils/serializeBankAccount'
import TokenChip from '../../../components/TokenChip';
import SelectItemChip from '../../../components/SelectItemChip';

import { TRANSFER_TYPES, TRANSFER_CURRENCY_TYPES, DEPOSIT_CRIPTO_TAX, OTHER_BANKS } from './constants'

import { Currency, getCurrencyBySymbol } from '../../../models/Currency';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  containerEditBank: {
    display: 'block',
    width: '100%',
},
  radioGroupEditBank: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
    justifyContent:'center'
  },
  cardField: {
    paddingLeft: 24,
    paddingRight: 24
  },
  bankInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  avatarSending: {
    backgroundColor: red[500]
  },
  avatarReceiving: {
    backgroundColor: green[500]
  },
  centerRowItem: {
    alignItems: 'center'
  },
  infoPixLimit: {
    marginTop: 1
  },
  editButton:{
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
      
    }
  },

})

const shouldDisplayChip = (option, props) =>{
  const { transferType, currencyType} = props;
  return ( option.bankName && (
    (transferType.toUpperCase() === TRANSFER_TYPES.WITHDRAWAL && currencyType !== TRANSFER_CURRENCY_TYPES.FIAT) ||
    transferType.toUpperCase() !== TRANSFER_TYPES.WITHDRAWAL))
};

const getItemLabel = (option, props) => {
   return ( shouldDisplayChip(option, props) ? <SelectItemChip
      itemCode={( option.isBankOthers ? OTHER_BANKS : option.bank)}
      itemName={option.bankName}/> : option.bankName)
};

const isSelectedBankValid = selectedBank => {
  return selectedBank.bank &&
    selectedBank.bankName &&
    selectedBank.agency &&
    selectedBank.account
}

const TransferForm = props => {
  const {
    user:{
      kyc_level
    },
    t,
    currencies,
    classes,
    sourceOptions,
    currencyType,
    bankInfo,
    transferType,
    handleTransferDestinationChange,
    handleCurrencyTypeChange,
    handleAmountPercentageChange,
    fees,
    balance,
    limitForOperation,
    values,
    isValid,
    handleSubmit,
    handleAgencyChange,
    handleAccountChange,
    handleAmountChange,
    handleChange,
    handleBlur,
    blockchain,
    handleNetworkChange,
    shouldShowNetworkOption,
    depositCurrency,
    showWithdrawalCriptoWarnings,
    showDepositCriptoWarnings,
    blockchainOptions,
    isCBRL,
    handlePixAddressChange,
    isCripto,
    warningCripto
  } = props 
  let showLimit = false

  if(kyc_level >= 1 && kyc_level < 4 ){
    showLimit = true  
  }
  const [editBank, setEditBank] = useState(false);
  const isAmountInputDisabled = !Boolean(values.destination.currency)
  const showPercantageButtons = !isAmountInputDisabled && transferType.toUpperCase() === TRANSFER_TYPES.WITHDRAWAL
  const showBankAccountInputs = values.destination && values.destination.bank === '001' && !values.destination.isBankOthers
  const selectedBank = values.destination && values.destination.isRegisteredBank && serializeBankAccount({ address: values.destination.address })
  const disabledFieldWithdrawal = isNilOrEmpty(selectedBank) && values.destination.bankName !== "PIX" && transferType.toUpperCase() === TRANSFER_TYPES.WITHDRAWAL && currencyType !=='CRIPTO'
  const brlCurrencyData = getCurrencyBySymbol(currencies, "BRL");
  const brzCurrencyData = getCurrencyBySymbol(currencies, "BRZ");
  const brlCurrency = new Currency(brlCurrencyData);
  const brzCurrency = new Currency(brzCurrencyData)

  const disabledReceiveCoin =
    values.destination.currency &&
    brzCurrency.symbol === values.destination.currency &&
    transferType.toUpperCase() === TRANSFER_TYPES.DEPOSIT &&
    brzCurrency.deposit.length === 0;

  const disabledSendCoin =
    values.destination.currency &&
    brzCurrency.symbol === values.destination.currency &&
    transferType.toUpperCase() === TRANSFER_TYPES.WITHDRAWAL &&
    brzCurrency.withdrawal.length === 0;
    
  useEffect(() => {
    if (disabledSendCoin) {
      let disabledCoinMessage = t("transactions.warningCripto.withdrawal");
      const info = t(`transactions.warningCripto.info`, {
        actions: disabledCoinMessage,
        coin: values.destination.currency,
      });
      warningCripto(info);
    } else if (disabledReceiveCoin) {
      let disabledCoinMessage = t("transactions.warningCripto.deposit");
      const info = t(`transactions.warningCripto.info`, {
        actions: disabledCoinMessage,
        coin: values.destination.currency,
      });
      warningCripto(info);
    }
  }, [disabledReceiveCoin, disabledSendCoin]);
    
    
  useEffect(() => {
    setEditBank(false)
    values.amount = undefined
    values.selectedPercentage = undefined
  }, [values.destination]);

  
  return (
    <form onSubmit={handleSubmit}>
      <CardHeader
        avatar={
          <Avatar
            aria-label='Recipe'
            className={transferType.toUpperCase() !== TRANSFER_TYPES.DEPOSIT ? classes.avatarSending : classes.avatarReceiving}
          >
            BP
          </Avatar>
        }
        title={`Nova solicitação de ${transferType.toUpperCase() === TRANSFER_TYPES.DEPOSIT ? 'depósito' : 'saque'}`}
      />

    <section style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: 24,
      paddingRight: 24,
    }}>
    <FormControl component="fieldset">
      <RadioGroup
        onChange={handleCurrencyTypeChange}
        value={currencyType}
        row
      >
        <FormControlLabel
          value={TRANSFER_CURRENCY_TYPES.FIAT}
          control={<Radio />}
          label={<TokenChip currency={brlCurrencyData} />}
        />
        <FormControlLabel
          value={TRANSFER_CURRENCY_TYPES.CRIPTO}
          control={<Radio />}
          label={<TokenChip currency={brzCurrencyData} />
        }
        />
      </RadioGroup>
    </FormControl>
      {!isCripto && <React.Fragment>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Typography
              variant="body2">{t(`transfers.${transferType}.selectOption.${currencyType.toLowerCase()}`)}</Typography>
        </div>
        <Select
            value={values.destination}
            options={sourceOptions}
            placeholder={t('forms.deposit.select_bank')}
            onChange={handleTransferDestinationChange}
            getLabel={option => getItemLabel(option, props)}
        />
      </React.Fragment>}

      { shouldShowNetworkOption && <React.Fragment>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Typography
              variant="body2">{t('transactions.networkBlockchain')}
          </Typography>
        </div>
        <Select
            value={blockchain}
            options={blockchainOptions}
            placeholder={t('forms.deposit.select_bank')}
            onChange={handleNetworkChange}
            getLabel={option => {
              if (option.label)
                return option.label;
              const networkDetails = blockchainOptions.find(networkObject => networkObject.value === option);
              return networkDetails ? networkDetails.label : t('forms.deposit.select_bank');

            }}
        />
      </React.Fragment> }

  {transferType.toUpperCase() === TRANSFER_TYPES.WITHDRAWAL && values.destination.isRegisteredBank &&
    <Grid style={{
      display: 'flex',
      justifyContent: 'space-around',
      margin: '20px 0'
    }} alignItems="flex-start" container spacing={3} >
      <Grid align='right' direction="column" item xs={12} style={{marginBottom:'5px'}}>
        {isSelectedBankValid(selectedBank) && <IconButton
          key="close"
          aria-label="Close"
          color="primary"
          className={classes.editButton}
          onClick={()=>{
            setEditBank(!editBank)
            values.amount = undefined
            values.selectedPercentage = undefined
        }}
        >
          {!editBank ? <Edit className={classes.icon} />: <Close className={classes.icon} /> }
        </IconButton>
       }
      </Grid>

        {isSelectedBankValid(selectedBank) && !editBank?
          <React.Fragment>
            <Grid align='center' direction="column" item xs={4}>
              <Typography variant="body2">{t('transactions.myBank')}:</Typography>
              <Typography color="textSecondary" variant="body2">
                {selectedBank.bank} - {selectedBank.bankName}
              </Typography>
            </Grid>
            <Grid align='center' direction="column" item xs={4}>
                <Typography variant="body2">{t('forms.agency.label')}:</Typography>
                <Typography color="textSecondary" variant="body2">
                {selectedBank.agency}
                </Typography>
            </Grid>
            <Grid align='center' direction="column" item xs={4}>
                <Typography variant="body2">{t('forms.account.label')}:</Typography>
                <Typography color="textSecondary" variant="body2">
                {selectedBank.account}
                </Typography>
            </Grid>
          </React.Fragment>
           :
          <React.Fragment>
            <Grid align='center' direction='column'>
              {isSelectedBankValid(selectedBank)?
              <Typography color="textSecondary" variant="body2" style={{marginTop: 10}}>
                {t('common.updateBank')}
              </Typography>
              :
              <Typography color="textSecondary" variant="body2" style={{marginTop: 10}}>
                {t('common.registerBank')}
              </Typography>
              }
              <BankAccountForm formWithdrawal={true} classEditBank={classes}/>
            </Grid>
          </React.Fragment>
        }
    </Grid>
  }

    {
    transferType.toUpperCase() === TRANSFER_TYPES.DEPOSIT &&
      currencyType === TRANSFER_CURRENCY_TYPES.CRIPTO &&
      R.not(R.isEmpty(values.destination)) &&
      <FieldRow style={{ marginTop: 20 }} fullWidth>
        <FormControlLabel
          control={
            <Checkbox
              name='minimumStable'
              checked={values.minimumStable}
              onChange={handleChange}
              disabled={disabledSendCoin}
            />
          }
          label={t(
            'transfers.withdrawal.minimumStable'
          )}
        />
      </FieldRow>
  }
      {
        transferType.toUpperCase() === TRANSFER_TYPES.DEPOSIT &&
        currencyType === TRANSFER_CURRENCY_TYPES.CRIPTO &&
        R.not(R.isEmpty(values.destination)) &&

        <FieldRow style={{ marginTop: 20 }} fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                name='agreeDepositAboveLimit'
                checked={values.agreeDepositAboveLimit}
                onChange={handleChange}
                disabled={disabledSendCoin}
              />
            }
            label={t(
              'transfers.withdrawal.agreeDepositAboveLimit'
            )}
          />
        </FieldRow>
      }
  {
    transferType.toUpperCase() === TRANSFER_TYPES.DEPOSIT &&
      currencyType === TRANSFER_CURRENCY_TYPES.CRIPTO &&
      R.not(R.isEmpty(values.destination)) &&

      <FieldRow style={{ marginTop: 20 }} fullWidth>
        <FormControlLabel
          control={
            <Checkbox
              name='agreeIN1888Deposit'
              checked={values.agreeIN1888Deposit}
              onChange={handleChange}
              disabled={disabledSendCoin}
            />
          }
          label={t(
            'transfers.withdrawal.agreeIN1888Deposit',
            { currency: values.destination.bankName }
          )}
        />

      </FieldRow>

  }
  {
    transferType.toUpperCase() === TRANSFER_TYPES.DEPOSIT &&
    values.destination && values.destination.currency &&
    DEPOSIT_CRIPTO_TAX[values.destination.currency] &&
      <FieldRow style={{ marginTop: 20 }} fullWidth>
        <FormControlLabel
          control={
            <Checkbox
              name='agreeDepositCriptoTax'
              checked={values.agreeDepositCriptoTax}
              onChange={handleChange}
            />
          }
          label={t(
            'transfers.withdrawal.agreeDepositCriptoTax',
            { tax: DEPOSIT_CRIPTO_TAX[values.destination.currency] }
          )}
        />
      </FieldRow>
  }



  {transferType.toUpperCase() === TRANSFER_TYPES.DEPOSIT &&
      <section style={{ width: '100%' }}>
        {
          currencyType === TRANSFER_CURRENCY_TYPES.CRIPTO &&
          R.not(R.isEmpty(values.destination)) &&
          values.agreeIN1888Deposit && values.agreeDepositAboveLimit &&
          values.minimumStable &&
          (DEPOSIT_CRIPTO_TAX[values.destination.currency]
            ? values.agreeDepositCriptoTax
            : true) &&
          <ReceiveCoins
            key='receiveCoinsss'
            form='receiveCoinsForm'
            hideBalance
            hideCurrencyRadio
            hideCurrencySelect
            destinationCurrency={values.destination.currency}
            networkInUse={depositCurrency}
            blockchainOptions={blockchainOptions}
            ignoreChecks
          />
        }
        {
          showBankAccountInputs &&
            <FieldRow className={classes.bankInfoContainer} fullWidth>
              <TextInput
                name='agency'
                value={values.agency}
                // onChange={handleChange}
                onChange={handleAgencyChange}
                onBlur={handleBlur}
              />

            <TextInput
              name='account'
              value={values.account}
              //onChange={handleChange}
              onBlur={handleBlur}
              InputProps={{
                inputComponent: NumberMaskFormatInput,
                inputProps: { onChange: handleAccountChange }
              }}
            />
          </FieldRow>
        }
      </section>
  }
  {transferType.toUpperCase() === TRANSFER_TYPES.WITHDRAWAL && currencyType === TRANSFER_CURRENCY_TYPES.CRIPTO &&
      <FieldRow fullWidth>
        <TextInput
          name='destination.address'
          i18nScope='forms.walletAddress'
          value={values.destination.address}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={!Boolean(values.destination.currency) || isCBRL || disabledReceiveCoin}
        />
      </FieldRow>
  }

      {(!(transferType.toUpperCase() === TRANSFER_TYPES.DEPOSIT &&
        currencyType === TRANSFER_CURRENCY_TYPES.CRIPTO ) && values.destination.bankName === "PIX") &&
      <FieldRow fullWidth>
        <TextInput
          name='destination.address'
          i18nScope='forms.pixKey'
          value={values.destination.address}
          onChange={handlePixAddressChange}
          onBlur={handleBlur}
          disabled={!Boolean(values.destination.currency)}
        />
        <Typography className={classes.infoPixLimit} variant="body2">
          {t('info.PIXLimitInformation')}
        </Typography>
      </FieldRow>}
  {!(transferType.toUpperCase() === TRANSFER_TYPES.DEPOSIT &&
    currencyType === TRANSFER_CURRENCY_TYPES.CRIPTO) &&
          <FieldRow fullWidth>
            <MoneyInput
              name='amount'
              i18nScope={`forms.${transferType}.amount`}
              formatter={brlCurrency._defaultFormatter()}
              onChange={handleAmountChange}
              onBlur={handleBlur}
              value={values.amount}
              disabled={!Boolean(values.destination.currency) || isCBRL || disabledFieldWithdrawal || editBank || disabledReceiveCoin}
              InputProps={{
                endAdornment: showPercantageButtons && (
                  <PercentageSwitchButtons
                    disabled={disabledFieldWithdrawal || editBank || disabledReceiveCoin}
                    selectedPercentage={values.selectedPercentage}
                    setPercentage={handleAmountPercentageChange}
                  />
                )
              }}
            />
          </FieldRow>
  }

        <FieldRow fullWidth style={{ margin: '20px auto' }} className={classes.centerRowItem}>
          <LimitsIndicator
            type={transferType}
            limits={limitForOperation}
            showLimitIndicator={showLimit}
            currency={values.destination.currency || 'BRL'}
          />
        </FieldRow>

        {
          isValid &&
          transferType.toUpperCase() === TRANSFER_TYPES.DEPOSIT &&
          currencyType === TRANSFER_CURRENCY_TYPES.FIAT &&
          R.not(R.isEmpty(values.destination)) &&
            <TransferFiatDetails
              source={values.source}
              destination={values.destination}
              bankInfo={bankInfo}
            />
        }

        { transferType.toUpperCase() === TRANSFER_TYPES.WITHDRAWAL && values.amount > 0 &&
            <FieldRow>
              <FeesIndicator
                balance={balance}
                fees={fees}
                amount={values.amount}
                type='withdrawal'
                currency={brlCurrencyData}
                operationType={values.operationType}
                isCripto={isCripto}
              />
            </FieldRow>
        }
        {
          transferType.toUpperCase() === TRANSFER_TYPES.WITHDRAWAL &&
            currencyType === TRANSFER_CURRENCY_TYPES.CRIPTO && (
            <section style={{ margin: '20px auto' }}>
              <FieldRow fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='agreeIN1888'
                      checked={values.agreeIN1888}
                      onChange={handleChange}
                      disabled={disabledReceiveCoin}
                    />
                  }
                  label={t(
                    'transfers.withdrawal.agreeIN1888',
                    { currency: values.destination.bankName }
                  )}
                />
              </FieldRow>

              {showWithdrawalCriptoWarnings && <FieldRow fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='agreeConvertToCripto'
                      checked={values.agreeConvertToCripto}
                      onChange={handleChange}
                      disabled={disabledReceiveCoin}
                    />
                  }
                  label={t('transfers.withdrawal.convertoToCriptoDisclaimerCBRL')}
                />
              </FieldRow> }

              {showWithdrawalCriptoWarnings &&  <FieldRow fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='agreeConfirmAddress'
                      checked={values.agreeConfirmAddress}
                      onChange={handleChange}
                      disabled={disabledReceiveCoin}
                    />
                  }
                  label={t(
                    'transfers.withdrawal.confirmAddressDisclaimerCBRL',
                    { currency: values.destination.currency })}
                />
              </FieldRow> }

              {showDepositCriptoWarnings && <FieldRow fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='agreeConvertToCripto'
                      checked={values.agreeConvertToCripto}
                      onChange={handleChange}
                    />
                  }
                  label={t('transfers.withdrawal.convertoToCriptoDisclaimer')}
                />
              </FieldRow> }

              {showDepositCriptoWarnings && <FieldRow fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      name='agreeConfirmAddress'
                      checked={values.agreeConfirmAddress}
                      onChange={handleChange}
                    />
                  }
                  label={t(
                    'transfers.withdrawal.confirmAddressDisclaimer',
                    { currency: values.destination.currency })}
                />
              </FieldRow>}
            </section>
        )}


        {!(transferType.toUpperCase() === TRANSFER_TYPES.DEPOSIT && currencyType === TRANSFER_CURRENCY_TYPES.CRIPTO) &&
            <FieldRow fullWidth>
              <SubmitButton
                disabled={!isValid || isCBRL || editBank}
                color="secondary"
                submitText={t(`transactions.${transferType}.action`)}
              />
            </FieldRow>
        }
</section>
    </form>
  )
}

export default withStyles(styles)(TransferForm)
