import React from "react";
import { connect } from "react-redux";
import { formStepsData } from "../_constants";
import {
  DialogContent,
  Grid,
  Stepper,
  IconButton,
  Step,
  StepLabel,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { compose } from "recompose";
import { translate } from "react-i18next";
import {
  reduxForm,
  getFormValues,
  isSubmitting,
  getFormSyncErrors,
  change,
  destroy,
} from "redux-form";
import PinFormInputStep from "./PinFormInputStep";
import PinFormStep1 from "./PinFormStep1";
import { changeCreatePinDialog, addSnack } from "../../../../redux/actions";
import createPin from "../../../../services/cbtcService/createPin";

const onSubmit = async (form, dispatch, props) => {
  const { handleClose, destroyForm, addSnackBar } = props;

  try {
    const { pin } = form;
    const newForm = {
      pin,
    };
    await createPin(newForm);
    handleClose();
    destroyForm();
    addSnackBar("GENERIC_SUCCESS");
  } catch (error) {
    addSnackBar ( error?.message_cod ?? "GENERIC_ERROR");
    return;
  }
};

const schema = {
  pin: {
    type: String,
    min: 4,
    regEx: /^[0-9]*$/,
  },
  confirmPin: {
    type: String,
    min: 4,
    regEx: /^[0-9]*$/,
  },
};

const validate = (values) => {
  const errors = {};
  for (const key in schema) {
    const value = values[key];
    const { type, min, regEx } = schema[key];

    if (type === String && (!value || value.length === 0)) {
      errors[key] = "REQUIRED_FIELD";
      continue;
    }
    const regexTest = regEx.test(value);
    if (!regexTest) {
      errors[key] = "ONLY_NUMBERS";
    } else if (type === String && value.length < min) {
      errors[key] = ["LENGHT_EQUAL_REQUIRED", { length: min }];
    }
  }
  if (
    values.confirmPin?.length == schema.confirmPin.min &&
    values.pin !== values.confirmPin
  ) {
    errors.confirmPin = "FIELDS_NOT_MATCH";
  }
  return errors;
};

const steps = [
  "forms.incomeAndWealthValidation.educativeDialog.title",
  "forms.pinCreateDialog.createPinStep.stepperLabel",
  "forms.pinCreateDialog.confirmPinStep.stepperLabel",
];

const stepComponents = [PinFormStep1, PinFormInputStep, PinFormInputStep];

const PinCreateBody = (props) => {
  const { t, classes, handleSubmit, formValues, clearField } = props;

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const stepData = formStepsData[activeStep];
  const Component = stepComponents[activeStep];

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    if ( stepData?.inputName ){
      clearField(stepData.inputName);
    }
  };


  return (
    <>
      <Grid
        container
        alignItems="center"
        justify="space-between"
        className={classes.financialHeaderContainer}
      >
        <Grid item>
          <IconButton onClick={handleBack} disabled={activeStep === 0}>
            <ArrowBack />
          </IconButton>
        </Grid>

        <Grid item className={classes.financialStepperContainer}>
          <Stepper
            className={classes.financialHeaderStepper}
            activeStep={activeStep}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel
                    classes={{ iconContainer: classes.stepperLabel }}
                    {...labelProps}
                  >
                    {t(label)}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
        <Grid item className={classes.minWidth50} height={50} />
      </Grid>
      <DialogContent>
        <Grid container>
          <form className={classes.w100} onSubmit={handleSubmit}>
            <Component
              {...props}
              formValues={formValues}
              stepData={stepData}
              onTap={
                activeStep === steps.length - 1 ? handleSubmit : handleNext
              }
            />
          </form>
        </Grid>
      </DialogContent>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  handleClose: () =>
    dispatch(
      changeCreatePinDialog({
        open: false,
      })
    ),
  clearField: (field) => dispatch(change("pinCreate", field, "")),
  destroyForm: () => dispatch(destroy("pinCreate")),
  addSnackBar: (message) => dispatch(addSnack({
    message
  })),
});

const mapStateToProps = (state) => {
  return {
    formValues: getFormValues("pinCreate")(state),
    submitting: isSubmitting("pinCreate")(state),
    formErrors: getFormSyncErrors("pinCreate")(state),
  };
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate(),
  reduxForm({
    validate,
    form: "pinCreate",
    onSubmit: onSubmit,
    destroyOnUnmount: false,
  })
);

export default enhance(PinCreateBody);
