export const styles = (theme) => ({
  dialogContainer: {
    backdropFilter: "blur(1.5px)",
    display: "flex",
    justifyContent: "right",
    fontFamily: "'Helvetica','Arial', 'sans-serif'",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  colorYellow: {
    color: "#f7b733",
  },
  dialogPaper: {
    margin: "auto 0 auto 0",
    height: "100%",
    maxHeight: "100%",
    borderRadius: "55px 0 0 55px",
    width: "32%",
    minWidth: "420px",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgb(247, 247, 247)",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "25px 25px 0 0",
      height: "80%",
      maxHeight: "80%",
      minHeight: "450px",
      margin: "auto 0 0 0",
      width: "100%",
      minWidth: "320px",
    },
  },
  copyToClipBoard: {
    cursor: "pointer",
    fontSize: "20px",
    color: "#656F79",
    transform: "rotate(90deg)",
    transform: "color 3s ease",
    "&:hover": {
      color: "#f7b733",
    },
  },
  exampleBixField: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    width: "100%",
    padding: "12px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  bgFullWhite: {
    backgroundColor: "white !important",
  },
  buttonBix: {
    backgroundColor: "#f7b733",
    color: "#101010",
    width: "100%",
    height: "50px",
    borderRadius: "8px",
    textTransform: "none",
  },
  blockBix: {
    backgroundColor: "#f0f0f0",
    padding: "18px 16px",
    borderRadius: "15px",
    gap: "10px",
  },
  containerBix: {
    display: "flex",
    gap: "20px",
  },
  bixIcon: {
    backgroundColor: "#f9f9f9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "40px",
    width: "100%",
    height: "40px",
    padding: "7px",
    borderRadius: "20px",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  dialogTitleContent: {
    lineHeight: "32px",
    margin: "auto",
    marginBottom: "13px",
    fontSize: "27px",
  },
  dialogTitleContentBix: {
    lineHeight: "41.66px",
    fontSize: "32px",
    fontWeight: "400",
    margin: "0",
  },
  closeButton: {
    border: "none",
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    width: "97%",
  },
  dialogContent: {
    maxWidth: "80%",
    margin: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
  },
  bixDialogContent: {
    maxWidth: "308px",
    width: "100%",
    margin: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "0 0 25px",
    gap: "10px",
  },
  cardMissionsDialogContent: {
    width: "100%",
    margin: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  qrCode: {
    backgroundColor: "rgb(255, 255, 255)",
    borderRadius: "20px",
    padding: "21px",
    width: "160px",
    margin: "auto",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  sectionBixContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
  },
  maxWidthMissionsContent: {
    width: "100%",
    maxWidth: "80.562659846547%",
  },
  storeButtons: {
    borderRadius: "40px",
    marginBottom: "10px",
    padding: "3% 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
    },
  },
  contentButton: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    gap: "5px",
    minHeight: "25px",
  },
  closeIcon: {
    "&:hover": {
      cursor: "pointer",
      opacity: "0.7",
    },
  },
  infoDialog: {
    width: "80%",
    margin: "auto",
    lineHeight: "23px",
    [theme.breakpoints.down("xs")]: {
      lineHeight: "20px",
      width: "80%",
    },
  },
  infoDialogBix: {
    lineHeight: "23px",
    textAlign: "start",
    width: "80%",
    margin: 0,
    [theme.breakpoints.down("xs")]: {
      lineHeight: "20px",
    },
  },
  titleBig: {
    color: "#070707",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
  },
  textContent: {
    textAlign: "left",
    color: "#696F74",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  textContentBix: {
    textAlign: "left",
    color: "#101010",
    fontSize: "14px",
    fontStyle: "normal",
    margin: "0",
    fontWeight: 400,
    lineHeight: "18.23px",
  },
  textStrong: {
    fontWeight: 700,
  },
  textMuted: {
    color: "#777777",
  },
  disclamer: {
    fontSize: "15px",
    "& svg": {
      fontSize: "19px",
    },
  },
  dialogTitle: {
    [theme.breakpoints.down("xs")]: {
      padding: "19px 24px 18px",
    },
  },
  gap45: {
    gap: "45px",
  },
  gap10: {
    gap: "10px",
  },
  fullWidth: {
    width: "100%",
  },
  cardLanding: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  bitybankButton: {
    color: "#101010",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    padding: "12px 0",
    borderRadius: "8px",
    background: " #F7B733",
  },
});
