import React from "react";

import { Typography, Grid, Button } from "@material-ui/core";
import PinField from "../../../../components/Forms/PinField";
import SubmitButton from "../../../../components/Forms/SubmitButton";

const PinFormInputStep = (props) => {
  const { t, stepData, classes, onTap, formErrors, submitting } = props;
  const isDisabled = formErrors && formErrors[stepData.inputName] || submitting;
  return (
    <Grid
      direction="column"
      alignItems="center"
      container
      className={classes.pinForm}
    >
      <Typography
        className={classes.educativeTitle}
        align="center"
        component={"h2"}
        variant="title"
      >
        {t(stepData.title)}
      </Typography>
      <Typography
        className="pin-form-description"
        align="center"
        component={"p"}
        variant="subheading"
        dangerouslySetInnerHTML={{ __html: t(stepData.description) }}
      />
      <PinField
        autoFocus
        className={classes.pinField}
        label={t("forms.pinCreateDialog.confirmPinStep.pinField.label")}
        name={stepData.inputName}
        variant="outlined"
        customClasses={classes}
      />
      {stepData.isFinalStep ? (
        <SubmitButton
          disabled={isDisabled}
          isSubmitting={submitting}
          className={classes.submitButton}
          color="primary"
          variant="raised"
          submitText={t(stepData.submitButtonLabel)}
          onClick={onTap}
        >
          {t(stepData.submitButtonLabel)}
        </SubmitButton>
      ) : (
        <Button
          disabled={isDisabled}
          className={classes.submitButton}
          color="primary"
          variant="raised"
          onClick={onTap}
        >
          {t(stepData.submitButtonLabel)}
        </Button>
      )}
    </Grid>
  );
};

export default PinFormInputStep;
