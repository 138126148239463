import { onlyNumbers } from "../../../../utils/regex";
import OTPInput from "react-otp-input";
import React from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "./styles";
import { translate } from "react-i18next";
import classNames from "classnames";

const getErrorData = (error) => {
  const errorData = {
    message: "",
    params: {},
  };
  if (error) {
    if (typeof error === "string") {
      errorData.message = error;
    } else {
      errorData.message = error[0];
      errorData.params = error[1];
    }
  }
  return errorData;
};

const CustomPinInput = (props) => {
  const inputSize = 4;
  const {
    input,
    classes,
    t,
    customClasses,
    meta: { error },
  } = props;
  const handlePinChange = (otp) => {
    if (onlyNumbers.test(otp)) {
      input.onChange(otp);
    }
  };

  const handleOnPaste = (event) => {
    const data = event.clipboardData.getData("text");
    if (onlyNumbers.test(data) && data.length === inputSize) {
      input.onChange(data);
    }
  };

  const renderInputOnChange = (e, inputProps) => {
    const { value } = e.target;
    if (onlyNumbers.test(value)) {
      inputProps.onChange(e);
    }
  };

  const renderInputOnPaste = (e, inputProps) => {
    const clipboardData = e.clipboardData || window.Clipboard;
    const pastedData = clipboardData.getData("text");
    if (onlyNumbers.test(pastedData) && pastedData.length >= inputSize) {
      inputProps.onPaste(e);
    } else {
      e.preventDefault();
    }
  };
  const errorData = getErrorData(error);
  return (
    <div>
      <OTPInput
        inputType={"text"}
        inputStyle={classNames(classes.pinInput, customClasses?.pinInput)}
        containerStyle={classes.pinInputContainer}
        renderInput={(inputProps, index) => (
          <input
            {...inputProps}
            onChange={(e) => {
              renderInputOnChange(e, inputProps);
            }}
            onPaste={(e) => {
              renderInputOnPaste(e, inputProps);
            }}
            aria-label={t("forms.pin.ariaLabel", { index: index + 1 })}
          />
        )}
        value={input.value}
        onChange={handlePinChange}
        numInputs={4}
        onPaste={handleOnPaste}
      />
      <div className={classes.helperText}>
        {t(errorData.message, errorData.params)}
      </div>
    </div>
  );
};

const enhance = compose(withStyles(styles), translate());

export default enhance(CustomPinInput);
