import * as R from 'ramda';
import { REMOVE_CREDENTIALS_ACCOUNT } from './actionTypes';

const initialState = {
  info: null,
  hasFetchedPixAccount: false
};


const updatePixAccount = (state, { response }) => {
  if (R.isEmpty(response)) {
    return {...state}
  }
  return R.merge(state, 
    response);
};

const updatePixAccountInfo = (state, { response }) => {
  if (R.isEmpty(response)) {
    return {...state}
  }
  const stateWithInfo = R.assoc('info', response.account, state);
  return R.assoc('hasFetchedPixAccount', true, stateWithInfo);
};

const removeCredentialsAccount = state => {
  return R.empty(state)
};

const CelcoinAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_SERVICE_SUCCEED':
      switch (action.requestType) {
        case 'fetchPixAccount':
          return updatePixAccount(state, action);
        case 'getPixAccount':
          return updatePixAccountInfo(state, action);
        default:
          return state;
      }
      case REMOVE_CREDENTIALS_ACCOUNT:
        return removeCredentialsAccount(state)
    default:
      return state;
  }
};

export default CelcoinAccountReducer;
