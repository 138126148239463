export const styles = (theme) => ({
  resetMargin: {
    margin: 0,
  },
  submitButton: {
    marginTop: theme.spacing.unit * 2,
    width: "100%",
  },
  defaultVerticalSpacing: {
    marginTop: theme.spacing.unit,
  },
  descriptionLabel: {
    marginBottom: theme.spacing.unit * 2,
  },
  linkStyle: {
    color: theme.palette.secondaryBase,
  },
  financialHeaderContainer: {
    padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px 0`,

    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing.unit * 2}px 0 0`,
    },
  },
  financialHeaderStepper: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  financialStepperContainer: {
    flex: 1,
    maxWidth: 400,
  },

  minWidth50: {
    minWidth: 20,
  },
  w100: {
    width: "100%",
  },

  dialogPaper: {
    [theme.breakpoints.down("xs")]: {
      margin: 24,
      width: "100%",
    },
  },
  dialogTitle: {
    "& h2": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },

      "@media (max-width: 450px)": {
        maxWidth: "150px",
      },
    },
  },

  stepperLabel: {
    [theme.breakpoints.down("xs")]: {
      "& svg": {
        width: "18px",
      },
    },
  },
  educativeTitle: {
    marginBottom: theme.spacing.unit * 2,
    color: theme.palette.primaryBase,
  },
  educativeStepIcon: {
    fontSize: "5rem",
    color: theme.palette.primaryBase,
    marginBottom: theme.spacing.unit,
  },
  educativeTipsContainer: {
    marginTop: theme.spacing.unit * 2,
    width: "95%",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  pinForm: {
    margin: "auto",
    width: "80%",
    "& .pin-form-description": {
      marginBottom: theme.spacing.unit * 2,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  pinInput: {
    width: "3.2rem !important",
    height: "3.2rem",
    fontSize: "1.5rem",
  },
});
