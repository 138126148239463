import {
  put, takeLatest,
  call, cancel, fork, take, select
} from 'redux-saga/effects';
import { delay } from 'redux-saga';

import isNilOrEmpty from '../../../utils/isNilOrEmpty';
import { checkIsRobotAccount } from '../../../utils/accountUtils';
import { getDeclarationTypesList, getDeclarationTypeByDocPending } from '../../../layout/Dialogs/UserDeclarationDialog/_constants';

import moment from 'moment';

import {
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCEED,

  ENABLE_SYNC_PROFILE,
  DISABLE_SYNC_PROFILE,

  UPDATE_PROFILE_REQUESTED,

  CONFIRM_UPDATE_PROFILE_REQUESTED,
  CONFIRM_UPDATE_PROFILE_SUCCEED,
  GENERATE_OTP_REQUESTED,
  ACTIVATE_OTP_REQUESTED,
  GENERATE_API_TOKEN_REQUESTED,
  GENERATE_API_TOKEN,
  START_PROFILE_CHANGES_VALIDATION_TOKEN,

} from './actionTypes';

import { changeUserDeclarationDialog, changeCreatePinDialog } from '../../actions';
import { fetchProfile } from './actions';
import { fetchService, removeCredentials, updateProfile, addDialog, generateApiToken, addValidationTokenDialog } from '../../actions';
import { SHORT_SYNC_INTERVAL } from '../../../config';

function* generateApiTokenRequested({form}) {
  try {
    yield put(addDialog({
      title: 'actions.generateApiTokenConfirm',
      renderComponent: 'apiTokenRequest',
      availableChoices: [
        {
          label: 'common.no',
          actionToTake: '',
          color: 'secondary',
          variant: 'raised',
        },
        {
          label: 'common.yes',
          actionToTake: 'confirm',
        },
      ],
      actionsToTake: {
        confirm: () => generateApiToken(form),
      },
    }));
  } catch (error) {
    console.error('generateApiToken', error);
  }
}

function* generateApiTokenSaga({form}) {
   try{
    yield put(fetchService({
      requestType: 'generateApiTokenSaga',
      responseToUser: {
        onSuccess: 'dialog',
        onFail: 'dialog',
      },
      paramns: {
        form,
        options: {
          cmd: 'create_tradingkey',
          method: 'POST',
        },
      },
    }));
   }
   catch(error){
    console.error('generateApiTokenSaga', error);
   }
}

function* updateProfileSaga({ profile }) {
  const data = JSON.stringify(profile);

  try {
    yield put(fetchService({
      requestType: 'updateProfile',
      successAction: START_PROFILE_CHANGES_VALIDATION_TOKEN,
      responseToUser: {
        onFail: 'dialog',
      },
      paramns: {
        privateService: true,
        form: {
          data,
        },
        options: {
          cmd: 'update_profile',
          method: 'POST',
        },
      },
    }));
  } catch (e) {
    console.error('updateProfileSaga', e);
  }
}

function* startProfileChangesValidationToken( ) {
  const auth_token = yield select(state => state.credentials.token);
  yield put (addValidationTokenDialog({ validationCmd: 'confirm_update', payload: {authToken: auth_token}}));
}

function* confirmUpdateProfileSaga({ token }) {
  try {
    yield put(fetchService({
      requestType: 'confirmUpdateProfile',
      successAction: CONFIRM_UPDATE_PROFILE_SUCCEED,
      responseToUser: {
        onSuccess: 'dialog',
        onFail: 'dialog',
      },
      paramns: {
        // privateService: true,
        form: {
          token,
        },
        options: {
          cmd: 'confirm_update',
          method: 'POST',
        },
      },
    }));
  } catch (error) {
    console.error('confirmUpdateProfileSaga', error);
  }
}

function* generateOtp() {
  try {
    yield put(fetchService({
      requestType: 'generateOtp',
      paramns: {
        privateService: true,
        options: {
          cmd: 'create_otp',
          method: 'POST',
        },
      },
    }));
  } catch (error) {
    console.error('generateOtp', error);
  }
}

function* activateOtp({ otp_token }) {
  try {
    yield put(fetchService({
      requestType: 'activateOtp',
      successAction: CONFIRM_UPDATE_PROFILE_SUCCEED,
      responseToUser: {
        onSuccess: 'dialog',
        onFail: 'dialog',
      },
      paramns: {
        privateService: true,
        form: {
          otp_token,
          timestamp: moment().format('X'),
        },
        options: {
          cmd: 'activate_otp',
          method: 'POST',
        },
      },
    }));
  } catch (error) {
    console.error('activateOtp', error);
  }
}

function* fetchProfileSaga(_action) {
  yield put(fetchService({
    requestType: 'fetchProfile',
    successAction: FETCH_PROFILE_SUCCEED,
    paramns: {
      privateService: true,
      options: {
        cmd: 'get_profile',
        method: 'POST',
      },
    },
  }));
}

function* fetchProfileSuceedSaga({ response }) {
  try {
    if (response) {
      const pendingSends = yield select(state => state.credentials.userDeclarationDialog.pendingSends);
      const dialogDeclarationTypes= getDeclarationTypesList(response.doc_pending);
      const hasPin = response?.has_pin;
      const taxId = response?.tax_id;
      const isRobotAccount = checkIsRobotAccount(taxId);

      if(dialogDeclarationTypes.length > 0 && isNilOrEmpty(pendingSends)) {
        const declarationType = getDeclarationTypeByDocPending(dialogDeclarationTypes[0]);
        const newPendingSends = dialogDeclarationTypes;
        yield put(changeUserDeclarationDialog({ open: true, declarationType, pendingSends: newPendingSends }));
      }
      if(!hasPin && !isRobotAccount) {
        yield put(changeCreatePinDialog({ open: true }));
      }
      yield put(updateProfile(response));
    }
  } catch (error) {
    console.error('fetchProfileSaga', error);
    yield put(removeCredentials());
  }
}

export function* removeProfileSaga() {
  try {
    yield put(updateProfile({}));
  } catch (error) {
    console.error('removeProfileSaga', error);
  }
}


function* enableSyncProfileSaga() {
    try {
      if (!document.hidden) {
        yield put(fetchProfile());
        yield call(delay, SHORT_SYNC_INTERVAL);
      } else {
        yield call(delay, SHORT_SYNC_INTERVAL);
      }
    } catch (error) {
      console.error('shortPublicLazySync', error);
      yield call(delay, SHORT_SYNC_INTERVAL);
    }
}

function* toggleSyncProfileSaga() {
  while (yield take(ENABLE_SYNC_PROFILE)) {
    const bgSyncTask = yield fork(enableSyncProfileSaga);
    yield take(DISABLE_SYNC_PROFILE);
    yield cancel(bgSyncTask);
  }
}


function* watchProfile() {
  yield takeLatest(FETCH_PROFILE, fetchProfileSaga);
  yield takeLatest(FETCH_PROFILE_SUCCEED, fetchProfileSuceedSaga);

  yield takeLatest(UPDATE_PROFILE_REQUESTED, updateProfileSaga);

  yield takeLatest(CONFIRM_UPDATE_PROFILE_REQUESTED, confirmUpdateProfileSaga);
  yield takeLatest(CONFIRM_UPDATE_PROFILE_SUCCEED, fetchProfileSaga);

  yield takeLatest(GENERATE_OTP_REQUESTED, generateOtp);
  yield takeLatest(ACTIVATE_OTP_REQUESTED, activateOtp);

  yield takeLatest(GENERATE_API_TOKEN_REQUESTED, generateApiTokenRequested);

  yield takeLatest(GENERATE_API_TOKEN, generateApiTokenSaga);
  yield takeLatest(START_PROFILE_CHANGES_VALIDATION_TOKEN, startProfileChangesValidationToken);

  yield takeLatest('REMOVE_CREDENTIALS', removeProfileSaga);
  yield toggleSyncProfileSaga();
}

export default watchProfile;
