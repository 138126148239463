import React from "react";

export const PixSymbol = (props) => {
  const { color, width = 21, height = 21 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3311 16.0216C15.9294 16.023 15.5313 15.9479 15.1599 15.8008C14.7886 15.6536 14.4513 15.4373 14.1675 15.1643L11.0421 12.162C10.9316 12.0605 10.7845 12.0039 10.6316 12.0039C10.4786 12.0039 10.3316 12.0605 10.221 12.162L7.0851 15.1725C6.80151 15.4459 6.46428 15.6627 6.09291 15.8103C5.72153 15.9579 5.32338 16.0334 4.92144 16.0324L4.30957 16.0324L8.2699 19.8343C8.86387 20.4033 9.66871 20.7228 10.5078 20.7228C11.3469 20.7228 12.1518 20.4033 12.7457 19.8343L16.714 16.0241L16.3311 16.0216Z"
        fill={color}
      />
      <path
        d="M4.9221 5.41165C5.32404 5.41062 5.72221 5.48608 6.09359 5.63368C6.46496 5.78127 6.80219 5.99806 7.08576 6.27152L10.2217 9.28264C10.3307 9.38696 10.4783 9.44554 10.6322 9.44554C10.7861 9.44554 10.9338 9.38696 11.0428 9.28264L14.1675 6.28293C14.4508 6.00912 14.788 5.79206 15.1594 5.64435C15.5308 5.49663 15.9291 5.4212 16.3311 5.42243L16.7074 5.42243L12.7391 1.61291C12.4453 1.33071 12.0964 1.10685 11.7124 0.95412C11.3284 0.801389 10.9169 0.722778 10.5012 0.722778C10.0856 0.722778 9.674 0.801389 9.29002 0.95412C8.90603 1.10685 8.55715 1.33071 8.2633 1.61291L4.30957 5.41165L4.9221 5.41165Z"
        fill={color}
      />
      <path
        d="M19.9896 8.57465L17.5916 6.27259C17.5375 6.29381 17.4798 6.30499 17.4213 6.30554L16.3309 6.30554C15.7635 6.3069 15.2196 6.52324 14.8174 6.90751L11.6927 9.90532C11.4113 10.175 11.03 10.3264 10.6323 10.3264C10.2347 10.3264 9.85337 10.175 9.57197 9.90532L6.43539 6.8961C6.03333 6.5116 5.48939 6.29503 4.92188 6.2935L3.58329 6.2935C3.52812 6.29231 3.47361 6.2818 3.42224 6.26245L1.00908 8.57465C0.41637 9.14486 0.0834961 9.9175 0.0834961 10.723C0.0834961 11.5286 0.41637 12.3012 1.00908 12.8714L3.41696 15.183C3.46822 15.1633 3.5228 15.1528 3.57801 15.152L4.92188 15.152C5.48938 15.1504 6.03329 14.9338 6.43539 14.5494L9.57131 11.5389C10.1383 10.9952 11.1264 10.9952 11.6927 11.5389L14.8174 14.538C15.2196 14.9222 15.7635 15.1386 16.3309 15.1399L17.4213 15.1399C17.4798 15.1403 17.5376 15.1515 17.5916 15.1729L19.9896 12.8708C20.2836 12.5887 20.5168 12.2538 20.6759 11.8852C20.8349 11.5165 20.9168 11.1214 20.9168 10.7224C20.9168 10.3234 20.8349 9.92829 20.6759 9.55966C20.5168 9.19104 20.2836 8.85611 19.9896 8.57402"
        fill={color}
      />
    </svg>
  );
};
