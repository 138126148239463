import React from 'react';
import { compose, withState } from 'recompose';
import { translate } from 'react-i18next';
import { reduxForm } from 'redux-form';
import SimpleSchema from 'simpl-schema';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import resendValidationMailService from '../../../../services/cbtcService/resendValidationMail';
import EmailField from '../../../../components/Forms/EmailField/EmailField';
import { EmailSchema } from '../../../../utils/Schemas';
import validateDoc from '../../../../components/Forms/asyncValidate';
import { addDialog } from '../../../../redux/actions';

const ResendValidationMail = ({
  open, setIsOpen, t, handleSubmit, submitSucceeded, submitting, invalid,
}) => {
  return (
    <div>
      <Button onClick={() => setIsOpen(true)}>{t('validate.resendVerificationMail')}</Button>
      <Dialog
        open={open}
        onClose={() => setIsOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">{t('validate.resendVerificationMail')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('validate.resendVerificationMailText')}
            </DialogContentText>
            <EmailField
              autoFocus
              margin="dense"
              name="email"
              autoComplete="email"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsOpen(false)} color="primary">
              {t('forms.cancel')}
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={(submitting || invalid) || submitSucceeded}
            >
              {t('screens.register.submit')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

const ResendValidationMailSchema = new SimpleSchema({
  email: EmailSchema,
});
const validate = values => validateDoc({ values, schema: ResendValidationMailSchema });

const enhance = compose(
  translate(),
  withState('open', 'setIsOpen', false),
  reduxForm({
    form: 'resendValidationMail',
    onSubmit: resendValidationMailService,
    validate,
    onSubmitSuccess: ({ message_cod }, dispatch) => // eslint-disable-line camelcase
      dispatch(addDialog({
        title: message_cod, // eslint-disable-line camelcase
        availableChoices: [
          {
            label: 'common.close',
            actionToTake: '',
            component: Link,
            to: '/login',
            color: 'secondary',
            variant: 'raised',
          },
        ],
      })),
    onSubmitFail: (err, dispatch, { message_cod }) => // eslint-disable-line camelcase
      dispatch(addDialog({
        title: message_cod, // eslint-disable-line camelcase
        availableChoices: [
          {
            label: 'common.close',
            actionToTake: '',
            color: 'secondary',
            variant: 'raised',
          },
        ],
      })),
  }),
);

export default enhance(ResendValidationMail);
