import React from 'react';
import * as R from 'ramda';
import {
  compose, pure, withProps, lifecycle, withHandlers,
} from 'recompose';

import { withStyles } from '@material-ui/core/styles';

import SwipeableTabs from '../../Tab/SwipeableTabs';
import PageTabComponent from './PageTabComponent';

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
});

const TabPageContainer = (props) => {
  const {
    tabIndex, children, tabLabels, onTabChange, classes, customStyles,
  } = props;

  return (
    <div className={classes.root}>
      <PageTabComponent tabLabels={tabLabels} onTabChange={onTabChange} tabIndex={tabIndex} />
      <SwipeableTabs
        index={tabIndex}
        slideCount={children.length}
        onChangeIndex={value => onTabChange(value)}
        axis="x"
        customStyles={customStyles}
      >
        {children}
      </SwipeableTabs>
    </div>
  );
};

const getTabIndexPathname = ({ tabRoutes, basePathnameRoute }) => tabIndex => {
  const tabIndexPathname = `${basePathnameRoute}${tabRoutes[tabIndex]}`;
  return tabIndexPathname;
};

const withPathnameProps = (props) => {
  const { location: { pathname }, match: { params: { subRoute } }, tabRoutes } = props;
  const baseLocation = R.tail(R.split('/', pathname));
  const basePathname = R.takeWhile(x => x !== subRoute, baseLocation);
  const basePathnameRoute = `/${R.join('/', basePathname)}/`;

  return {
    subRoute,
    getPathname: getTabIndexPathname({ tabRoutes, basePathnameRoute }),
  };
};

const enhance = compose(
  pure,
  withStyles(styles),
  withProps(({ children }) => ({
    tabLabels: children.map(({ props: { label } }) => label),
    tabRoutes: children.map(({ props: { route } }) => route),
  })),
  withProps(withPathnameProps),
  withProps(({ subRoute, tabRoutes, getPathname }) => {
    const routeTabIndex = R.findIndex(R.equals(subRoute), tabRoutes);
    const tabIndex = routeTabIndex >= 0 ? routeTabIndex : 0;
    const tabIndexPathname = getPathname(tabIndex);
    return { tabIndex, tabIndexPathname };
  }),
  lifecycle({
    componentDidMount() {
      const {
        subRoute, history, tabIndexPathname,
      } = this.props;
      if (!subRoute) {
        history.replace(tabIndexPathname);
      }
    },
  }),
  withHandlers({
    onTabChange: ({ getPathname, history }) => value => history.replace(getPathname(value)),
  }),

);

export default enhance(TabPageContainer);
