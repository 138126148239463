export const convertToTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const convertTimestampToMMSS = (timestamp) => {
  const date = new Date(timestamp);
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  // Pad minutes and seconds with leading zeros if necessary
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");
  return `${formattedMinutes}:${formattedSeconds}`;
};

export const convertToLowercase = (str) => str && str.toLowerCase();
