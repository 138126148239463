import React from "react";
import { Field } from "redux-form";
import TextFieldComponent from "../TextFieldComponent";
import { withStyles } from "@material-ui/core";
import { compose } from "recompose";
import classNames from "classnames";
import { styles } from "./styles";
import { convertToLowercase } from "../../../utils/strings";

function onKeyPressed(e) {
  if (e.charCode === 42) {
    e.preventDefault();
  }
}

const EmailField = (props) => {
  const { inputProps, classes } = props;
  const updatedInputProps = {
    ...inputProps,
    className: classNames(inputProps?.className, classes.inputField),
  };

  return (
    <Field
      {...props}
      inputProps={updatedInputProps}
      type="email"
      id="emailfield"
      onKeyPress={onKeyPressed}
      normalize={convertToLowercase}
      component={TextFieldComponent}
    />
  );
};

const enhance = compose(withStyles(styles));

export default enhance(EmailField);
