export const styles = (theme) => ({
  pinInputContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  pinInput: {
    textSecurity: "disc",
    fontFamily: "text-security-disc",
    width: "2.2rem !important",
    height: "2.2rem",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #E0E0E0",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    color: "#101010",
    textAlign: "center",
    "&:focus": {
      border: "1px solid #F7B733",
    },
  },
  helperText: {
    color: theme.palette.error.main,
    margin: 0,
    fontSize: "0.75rem",
    textAlign: "left",
    marginTop: "8px",
    minHeight: "1em",
    fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    lineHeight: "1em",
  },
});
