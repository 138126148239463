const VERIFY_CAPTCHA = 'VERIFY_CAPTCHA';
const UPDATE_CAPTCHA = 'UPDATE_CAPTCHA';

const LOGIN = 'LOGIN';
const UPDATE_CREDENTIALS = 'UPDATE_CREDENTIALS';
const REMOVE_CREDENTIALS = 'REMOVE_CREDENTIALS';
const END_SESSION = 'END_SESSION';

const ADD_INTERCOM_HASH = 'ADD_INTERCOM_HASH'
  
const UPDATE_INTERCOM_HASH = 'UPDATE_INTERCOM_HASH';

const ADD_CELCOIN_REGISTER_DIALOG = 'ADD_CELCOIN_REGISTER_DIALOG';
const REMOVE_CELCOIN_REGISTER_DIALOG = 'REMOVE_CELCOIN_REGISTER_DIALOG';

const REQUEST_VALIDATION = 'REQUEST_VALIDATION';
const RESOLVE_VALIDATION = 'RESOLVE_VALIDATION';
const VALIDATE_ACC_SUCCEED = 'VALIDATE_ACC_SUCCEED';

const REQUEST_REGISTER = 'REQUEST_REGISTER';
const RESOLVE_REGISTER = 'RESOLVE_REGISTER';

const OTP_VALIDATE_LOGIN = 'OTP_VALIDATE_LOGIN';
const LOGIN_OTP = 'LOGIN_OTP';
const GET_PROFILE = 'GET_PROFILE';
const FINISH_LOGIN = 'FINISH_LOGIN';

const ADD_OTP_DIALOG = 'ADD_OTP_DIALOG';
const ADD_OTP_APP_DIALOG = 'ADD_OTP_APP_DIALOG';
const CLOSE_OTP_APP_DIALOG = 'CLOSE_OTP_APP_DIALOG'
const CLOSE_OTP_DIALOG = 'CLOSE_OTP_DIALOG';

const ADD_RESEND_EMAIL_DIALOG = 'ADD_RESEND_EMAIL_DIALOG';
const CLOSE_RESEND_EMAIL_DIALOG = 'CLOSE_RESEND_EMAIL_DIALOG';

const ADD_ASIDE_DIALOG = 'ADD_ASIDE_DIALOG';
const CLOSE_ASIDE_DIALOG = 'CLOSE_ASIDE_DIALOG';

const ADD_VALIDATION_TOKEN_DIALOG = 'ADD_VALIDATION_TOKEN_DIALOG';
const CLOSE_VALIDATION_TOKEN_DIALOG = 'CLOSE_VALIDATION_TOKEN_DIALOG';
const REMOVE_INTERCOM_HASH = 'REMOVE_INTERCOM_HASH'
const ADD_SMS_TOKEN_DIALOG = 'ADD_SMS_TOKEN_DIALOG';
const CLOSE_SMS_TOKEN_DIALOG = 'CLOSE_SMS_TOKEN_DIALOG';

const ADD_RENEW_CONNECTION_DIALOG = 'ADD_RENEW_CONNECTION_DIALOG';
const CLOSE_RENEW_CONNECTION_DIALOG = 'CLOSE_RENEW_CONNECTION_DIALOG';

const UPDATE_TOKEN = 'UPDATE_TOKEN';

const RESEND_VALIDATION = 'RESEND_VALIDATION';

const ADD_SESSION_EXPIRED_DIALOG = 'ADD_SESSION_EXPIRED_DIALOG';
const SEND_SMS_PHONE_VALIDATION = 'SEND_SMS_PHONE_VALIDATION';
const VALIDATE_PHONE_SMS_TOKEN = 'VALIDATE_PHONE_SMS_TOKEN';
const PHONE_SMS_VALIDATION_SUCCEED = 'PHONE_SMS_VALIDATION_SUCCEED';

const GENERATE_DEVICE_ID = 'GENERATE_DEVICE_ID';

const SET_USER_LOGGED_IN_WITH_SAVED_TOKEN = 'SET_USER_LOGGED_IN_WITH_SAVED_TOKEN';

const CHANGE_USER_DECLARATION_DIALOG = 'CHANGE_USER_DECLARATION_DIALOG';
const CHANGE_CREATE_PIN_DIALOG = 'CHANGE_CREATE_PIN_DIALOG';
const RESET_OTP_REQUESTED = 'RESET_OTP_REQUESTED';
const RESET_OTP_REQUESTED_SUCCESS = 'RESET_OTP_REQUESTED_SUCCESS';


export {
  VERIFY_CAPTCHA,
  UPDATE_CAPTCHA,
  LOGIN,
  RESET_OTP_REQUESTED,
  RESET_OTP_REQUESTED_SUCCESS,

  END_SESSION,
  UPDATE_CREDENTIALS,
  REMOVE_CREDENTIALS,

  ADD_INTERCOM_HASH,

  OTP_VALIDATE_LOGIN,
  ADD_OTP_DIALOG,
  ADD_OTP_APP_DIALOG,
  CLOSE_OTP_APP_DIALOG,
  CLOSE_OTP_DIALOG,
  REMOVE_INTERCOM_HASH,
  ADD_RESEND_EMAIL_DIALOG,
  CLOSE_RESEND_EMAIL_DIALOG,

  REMOVE_CELCOIN_REGISTER_DIALOG,
  ADD_CELCOIN_REGISTER_DIALOG,

  ADD_ASIDE_DIALOG,
  CLOSE_ASIDE_DIALOG,

  REQUEST_VALIDATION,
  RESOLVE_VALIDATION,
  VALIDATE_ACC_SUCCEED,

  REQUEST_REGISTER,
  RESOLVE_REGISTER,

  RESEND_VALIDATION,
  UPDATE_TOKEN,
  LOGIN_OTP,
  GET_PROFILE,
  FINISH_LOGIN,
  ADD_VALIDATION_TOKEN_DIALOG,
  CLOSE_VALIDATION_TOKEN_DIALOG,
  ADD_RENEW_CONNECTION_DIALOG,
  CLOSE_RENEW_CONNECTION_DIALOG,
  ADD_SESSION_EXPIRED_DIALOG,
  ADD_SMS_TOKEN_DIALOG,
  CLOSE_SMS_TOKEN_DIALOG,
  SEND_SMS_PHONE_VALIDATION,
  VALIDATE_PHONE_SMS_TOKEN,
  PHONE_SMS_VALIDATION_SUCCEED,
  GENERATE_DEVICE_ID,
  UPDATE_INTERCOM_HASH,
  SET_USER_LOGGED_IN_WITH_SAVED_TOKEN,
  CHANGE_USER_DECLARATION_DIALOG,
  CHANGE_CREATE_PIN_DIALOG,
};
