import * as R from 'ramda';
import * as actions from './actionTypes';
import { currencies } from '../../mocks/currencyLocalRepository';

const initialState = {
  currencies: currencies,
  markets: {},
  hasFetchedCurrencies: false
};

const updateCurrencies = (state, { currencies }) => {
  return R.merge(state, {
    currencies: Object.values(currencies).sort((a, b) =>
      a.symbol.localeCompare(b.symbol)
    ),
    hasFetchedCurrencies: true,
  });
};

const updateMarket = (state, { response }) => {
  delete response['success'];
  return R.merge(state, {
    markets: response
  });
};

const currenciesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_CURRENCIES:
      return updateCurrencies(state, action);

    case actions.UPDATE_MARKET:
      return updateMarket(state, action);
    default:
      return state;
  }
};

export default currenciesReducer;
