import CurrencyIcon from "./CurrencyIcon";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
    component: {
        display: 'flex',
        gap: '8px',
        alignItems: 'center'
    },
    labelComponent: {
        display: 'flex',
        flexDirection: 'column'
    },
    symbol: {
        color: 'white',
        fontSize: 13,
        marginBottom: '-2px'
    },
    name: {
        color: 'white',
        fontSize: 10,
        fontStyle: 'italic',
        marginTop: '-1px',
        lineHeight: `10px`
    }
});

const CurrencyIconLabel = ({currency, classes}) => {
    return (
      <div className={classes.component}>
        <CurrencyIcon currency={currency} />
        <div className={classes.labelComponent}>
          <Typography className={classes.symbol}>{currency.symbol}</Typography>
          <Typography className={classes.name}>{currency.getExhibitionName()}</Typography>
        </div>
      </div>
    );
}

export default withStyles(styles)(CurrencyIconLabel);
