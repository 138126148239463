import React from "react";
import { Dialog, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { translate } from "react-i18next";
import PinCreateBody from "./components/PinCreateBody";
import { styles } from "./styles";

const PinCreateDialog = (props) => {
  const {
    dialogOptions: { open },
    classes,
  } = props;

  return (
    <Dialog
      open={open}
      fullWidth
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <PinCreateBody classes={classes} />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  dialogOptions: state.credentials.createPinDialog,
});

const enhance = compose(
  connect(mapStateToProps, null),
  withStyles(styles),
  withHandlers({}),
  translate()
);

export default enhance(PinCreateDialog);
