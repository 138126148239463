export const bityIndexedDBStores = {
  currencies: "Currencies",
};

export class BityIndexedDB {
  dbName = "bityDB";
  version = 1;
  db;

  validateAndCreateObjectStores(db) {
    Object.values(bityIndexedDBStores).forEach((storeName) => {
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, {
          keyPath: "symbol",
        });
      }
    });
  }

  async initDB() {
    try {
      return new Promise((resolve) => {
        let request = indexedDB.open(this.dbName);
        request.onupgradeneeded = () => {
          this.db = request.result;
          this.validateAndCreateObjectStores(this.db);
        };

        request.onsuccess = () => {
          this.db = request.result;
          this.version = this.db.version;
          resolve(true);
        };

        request.onerror = () => {
          resolve(false);
        };
      });
    } catch (error) {
      return new Error("BityIndexedDB - initDB: " + error);
    }
  }

  async checkStoreHasValues(storeName) {
    return new Promise((resolve) => {
      let request = indexedDB.open(this.dbName, this.version);
      request.onsuccess = () => {
        try {
          this.db = request.result;
          const transaction = this.db.transaction(storeName, "readonly");
          const store = transaction.objectStore(storeName);
          const countRequest = store.count();
          countRequest.onsuccess = () => {
            resolve(countRequest.result > 0);
          };

          countRequest.onerror = () => {
            resolve(false);
          };
        } catch (error) {
          console.error("checkStoreHasValues", error);
          resolve(false);
        }
      };

      request.onerror = () => {
        resolve(false);
      };
    });
  }

  async addData(storeName, data) {
    return new Promise((resolve, reject) => {
      let request = indexedDB.open(this.dbName, this.version);

      request.onsuccess = () => {
        try {
          this.db = request.result;
          const tx = this.db.transaction(storeName, "readwrite");
          const store = tx.objectStore(storeName);
          store.put(data);
          resolve(data);
        } catch (error) {
          console.error("addData - onsuccess", error);
          reject("BityIndexedDB - addData - onsuccess: " + error);
        }
      };

      request.onerror = () => {
        const error = request?.error?.message;
        if (error) {
          reject("BityIndexedDB - addData - onerror: " + error);
        } else {
          reject("BityIndexedDB - addData - onerror: Unknown error");
        }
      };
    });
  }

  async getStoreData(storeName) {
    return new Promise((resolve, reject) => {
      let request = indexedDB.open(this.dbName, this.version);

      request.onsuccess = () => {
        try {
          this.db = request.result;
          const tx = this.db.transaction(storeName, "readonly");
          const store = tx.objectStore(storeName);
          const res = store.getAll();
          res.onsuccess = () => {
            resolve(res.result);
          };
        } catch (error) {
          reject("BityIndexedDB - getStoreData - onsuccess: " + error);
        }
      };

      request.onerror = () => {
        reject("BityIndexedDB - getStoreData - onerror: Unknown error");
      };
    });
  }

  async deleteData(storeName, key) {
    return new Promise((resolve, reject) => {
      let request = indexedDB.open(this.dbName, this.version);

      request.onsuccess = () => {
        try {
          this.db = request.result;
          const tx = this.db.transaction(storeName, "readwrite");
          const store = tx.objectStore(storeName);
          const res = store.delete(key);

          res.onsuccess = () => {
            resolve(true);
          };
          res.onerror = () => {
            reject("BityIndexedDB - deleteData - onerror: Unknown error");
          };
        } catch (error) {
          reject("BityIndexedDB - deleteData - onsuccess: " + error);
        }
      };

      request.onerror = () => {
        reject("BityIndexedDB - deleteData - onerror: Unknown error");
      };
    });
  }

  async clearStore(storeName) {
    return new Promise((resolve, reject) => {
      let request = indexedDB.open(this.dbName, this.version);

      request.onsuccess = () => {
        try {
          this.db = request.result;
          const tx = this.db.transaction(storeName, "readwrite");
          const store = tx.objectStore(storeName);
          const res = store.clear();

          res.onsuccess = () => {
            resolve(true);
          };
          res.onerror = () => {
            reject("BityIndexedDB - clearStore - onerror: Unknown error");
          };
        } catch (error) {
          reject("BityIndexedDB - clearStore - onsuccess: " + error);
        }
      };

      request.onerror = () => {
        reject("BityIndexedDB - clearStore - onerror: Unknown error");
      };
    });
  }
}

export const bityIndexedDB = new BityIndexedDB();
