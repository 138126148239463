import { BixIcon } from "../../../../components/SvgIcon/components/BixIcon";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import CopyToClipboard from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import { PixSymbol } from "../../../../components/SvgIcon/components/PixSymbol";
import { SmartphoneOutlined } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import DangerouslySetInnerHTML from "../../../../components/DangerouslySetInnerHTML";
import { FIREBASE_LINKS } from "../../../../config";
import ActiveIndicator from "../../../../components/ActivityIndicator";

const KnowBixDialog = ({
  t,
  classes,
  title,
  keyPixAccount,
  addSnackBar,
  getPixAccount,
}) => {

  const pixDataAccount = keyPixAccount?.info
  const hasFetchedPixAccount = keyPixAccount?.hasFetchedPixAccount

  if (!pixDataAccount?.keys?.length) {
    getPixAccount();
  }
   
  const pixKey = pixDataAccount?.keys[0]?.key || t("screens.bix.pixArea.depositEmail");

  return (
    <>
      <div className={classNames(classes.sectionBixContainer)}>
        <h2 className={classes.dialogTitleContentBix}>
          {title ? t(title) : t("screens.bix.title")}
        </h2>
        <p className={classNames(classes.infoDialogBix, classes.textMuted)}>
          {t("screens.bix.description")}
        </p>
      </div>

      <div className={classNames(classes.flexColumn, classes.gap10)}>
        <div className={classNames(classes.containerBix, classes.blockBix)}>
          <div className={classes.bixIcon}>
            <SmartphoneOutlined className={classes.colorYellow} />
          </div>
          <DangerouslySetInnerHTML
            className={classes.textContentBix}
            title={t("screens.bix.buyByApp")}
          />
        </div>

        <div className={classNames(classes.flexColumn, classes.blockBix)}>
          <div className={classes.containerBix}>
            <div className={classes.bixIcon}>
              <PixSymbol color="#f7b733" />
            </div>
            <DangerouslySetInnerHTML
              className={classes.textContentBix}
              title={t("screens.bix.pixArea.description")}
            />
          </div>
          <div
            className={classNames(
              classes.exampleBixField,
              classes.spaceBetween
            )}
          >
            {!hasFetchedPixAccount ? (
              <ActiveIndicator />
            ) : (
              <>
                <DangerouslySetInnerHTML
                  className={classNames(
                    classes.textContentBix,
                    classes.textStrong
                  )}
                  title={pixKey}
                />
                <CopyToClipboard
                  text={pixKey}
                  className={classes.copyToClipBoard}
                >
                  <FontAwesomeIcon
                    icon={faClone}
                    onClick={() =>
                      addSnackBar({ message: t("common.contentCopied") })
                    }
                  />
                </CopyToClipboard>
              </>
            )}
          </div>
        </div>

        <div className={classNames(classes.flexColumn, classes.blockBix)}>
          <div className={classes.containerBix}>
            <div className={classes.bixIcon}>
              <BixIcon color="#f7b733" />
            </div>
            <DangerouslySetInnerHTML
              className={classes.textContentBix}
              title={t("screens.bix.bixArea.description")}
            />
          </div>
          <div className={classes.exampleBixField}>
            <p
              className={classNames(classes.textContentBix, classes.textMuted)}
            >
              {t("screens.bix.bixArea.example")}
            </p>
            <DangerouslySetInnerHTML
              className={classes.textContentBix}
              title={t("screens.bix.bixArea.bixBTC")}
            />
          </div>
        </div>
      </div>
      <Button
        color="secondary"
        variant="contained"
        className={classes.buttonBix}
        href={FIREBASE_LINKS.infoDepositBixUrl}
        target="_blank"
      >
        {t("screens.bix.labelButton")}
      </Button>
    </>
  );
};

export default KnowBixDialog;
