import { MgmIcon } from "./MgmIcon";
import { CryptoConverterIcon } from "./CryptoConverterIcon";
import { CashbackIcon } from "./CashbackIcon";
import { MissionsHexagonal } from "./MissionsHexagonal";
import { BixIcon } from "./BixIcon";
import { PixSymbol } from "./PixSymbol";

const icons = {
  MgmIcon,
  CryptoConverterIcon,
  CashbackIcon,
  MissionsHexagonal,
  BixIcon,
  PixSymbol
};

export default icons;
