import React from "react";
import CurrencyIconLabel from "../../components/CurrencyIconLabel";
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';
import CurrencyFormat from "../../components/Currency/CurrencyFormat";
import CriptoFormat from "../../utils/NumberFormat/CriptoFormat";
import {compose} from "recompose";
import {translate} from "react-i18next";
import { Currency } from "../../models/Currency";


const styles = (_theme) => ({
  component: {
    width: "100%",
    cursor: "pointer",
    display: "flex",
    backgroundColor: "rgba(101, 111, 121, 0.4)",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
    borderRadius: "50px",
    padding: "10px",
    alignItems: "self-end",
    height: "55px",

    [_theme.breakpoints.down("xs")]: {
      height: "auto",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "center",
      paddingLeft: "20px",
      gap: "5px",
    },

  },
  "@keyframes placeholderShimmer": {
    "0%": {
      backgroundPosition: "-468px 0",
    },
    "100%": {
      backgroundPosition: "468px 0",
    },
  },
  shine: {
    width: "100%",
    background: "rgba(101, 111, 121, 0.4)",
    backgroundImage:
      "linear-gradient(to right, #656F79 0%, #656F79 20%, #656F79 40%, #656F79 100%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "800px 104px",
    display: "flex",
    height: "55px",
    padding: "10px",
    borderRadius: "50px",
    alignItems: "self-end",
    "-webkit-animation-duration": "1s",
    "-webkit-animation-fill-mode": "forwards",
    "-webkit-animation-iteration-count": "infinite",
    "-webkit-animation-name": "placeholderShimmer",
    "-webkit-animation-timing-function": "linear",

    [_theme.breakpoints.down("xs")]: {
      height: "auto",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "space-around",
      paddingLeft: "40px",
      gap: "5px",
    },
  },
  componentSelected: {
    width: "100%",
    display: "flex",
    backgroundColor: "rgba(101, 111, 121, 1)",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
    borderRadius: "50px",
    padding: "10px",
    alignItems: "self-end",
    height: "55px",

    [_theme.breakpoints.down("xs")]: {
      height: "auto",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "space-around",
      paddingLeft: "20px",
      gap: "5px", 
    },
  },
  infoComponent: {
    width: "80%",
    display: "flex",
    marginLeft: 5,

    [_theme.breakpoints.down("xs")]: {
      display: "grid",
      width: "100%",
      gridTemplateColumns: "1fr 1fr",
      columnGap: 20,
      marginLeft: 0,
    },
  },
  balanceInfo: {
    display: "flex",
    flexDirection: "column",
    width: "33.3%",

    [_theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  currencyTitle: {
    width: "20%",
    [_theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  currencyAllCurrenciesTitle: {
    width: "100%",
  },
  label: {
    color: "white",
    fontSize: 10,
    marginBottom: "-2px",
  },
  value: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    direction: "ltr",
    color: "white",
    fontSize: 14,
    marginTop: "-2px",
  },
  totalValue: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    direction: "ltr",
    color: "white",
    fontSize: 14,
    marginTop: "-2px",
    whiteSpace: "nowrap",
    "&:hover": {
      zIndex: 10,
      overflow: "visible",
    },
  },
});


const CurrencyBalanceDetails = ({ currencyInfo,
                                quoteCurrencyInfo,
                                currencyBalance,
                                currencyLocked,
                                currencyTicker,
                                classes,
                                selected,
                                onClick,
                                isFiat,
                                loading, t,
                                hasBalanceInfo = true 
                              }) => {
    const currency = new Currency(currencyInfo);
    const quoteCurrency = new Currency(quoteCurrencyInfo);
    const precision = currency.formatter.decimalScale;
    const tickerLast = currencyTicker && currencyTicker.last || 0;
    const brlTotal = (currencyBalance + currencyLocked) * tickerLast;
    const mainComponentClass = loading ? classes.shine : (selected ? classes.componentSelected : classes.component);
    return (
      <div onClick={loading ? null : onClick} className={mainComponentClass}>
        <div className={hasBalanceInfo ? classes.currencyTitle : classes.currencyAllCurrenciesTitle}>
          <CurrencyIconLabel currency={currency} />
        </div>

        {hasBalanceInfo && (
        <div className={classes.infoComponent}>
          <div className={classes.balanceInfo}>
            <Typography className={classes.label}>
              {t("common.available")}:
            </Typography>
            <Typography className={classes.value}>
              <CriptoFormat
                value={Number(currencyBalance)}
                decimalScale={precision}
              />
            </Typography>
          </div>
          <div className={classes.balanceInfo}>
            <Typography className={classes.label}>
              {t("common.inUse")}:
            </Typography>
            <Typography className={classes.value}>
              <CriptoFormat
                value={Number(currencyLocked)}
                decimalScale={precision}
              />
            </Typography>
          </div>
          {!isFiat && (
            <div className={classes.balanceInfo}>
              <Typography className={classes.label}>
                {t("common.valueBrl")}:
              </Typography>
              <Typography className={classes.totalValue}>
                <CurrencyFormat
                  value={brlTotal}
                  isFiat
                  formatter={quoteCurrency._defaultFormatter()}
                />
              </Typography>
            </div>
          )}
        </div>
        )}
        </div>
    );
}

const enhance = compose(
    withStyles(styles),
    translate()
);

export default enhance(CurrencyBalanceDetails);