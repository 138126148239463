import React, { useEffect } from 'react';
import { OTP_DISABLE_LOW_LEVEL_USERS_URL, OTP_DISABLE_HIGH_LEVEL_USERS_URL } from '../../../utils/constants';


import {
  compose, pure, withHandlers, withProps, withState
} from 'recompose';
import { connect } from 'react-redux';

import { translate } from 'react-i18next';
import { reduxForm } from 'redux-form';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

import OtpForm from './OtpForm';
import AccountListItemField from '../components/AccountListItemField';
import validate from '../../../utils/validate';
import SubmitButton from '../../../components/Forms/SubmitButton';
import { updateProfileRequested, addDialog, generateOtpRequested } from '../../../redux/actions';
import renderBranch from '../../../components/renderBranch';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Security } from '@material-ui/icons';

const styles = theme => ({
  itemsContainer: {
    padding: '0 24px'
  },
  '@media screen and (min-width: 600px)': {
    list: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
    },
  },
  list: {
    flexDirection: 'row',
  },
  listItem: {
    minHeight: '84px',
  },
  listItemButton: {
    maxHeight:  '60px',
    width: '100%',
    height: '100%',
  },
  root: {
    flex: 1,
    paddingBottom: 100,
  },
  fieldItem: {
    padding: 0,
    ...theme.typography.body1,
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
});

const GeneralInfoFormAndOtpButton = props => {
  const {
    requestGenerateOtp,
    generateOtp,
    otp_active,
    t,
    submitting,
    pristine,
    invalid,
    handleSubmit,
    setOpenPhrase,
    setOpen2fa,
    open2fa,
    openPhrase,
    classes,
    actualTab,
    hasPin,
    pinIsRequiredToWithdrawalDialog
  } = props;

  // this hooks makes the components close when the user change tabs
  useEffect(() => {
    actualTab !== 'security' && setOpen2fa(false);
    actualTab !== 'security' && setOpenPhrase(false);
  })

  // this component actually renders the two lower buttons of security tab in profile page.
  // It represents the button and form that changes the security phrase of the user, but it calls
  // the components that renders the form of the 2fa key.
  return (
    <>
      <Grid className={classes.list} container spacing={24}>
        <Grid item className={classes.listItem} sm={6} xs={12}>
          <Button
          className={classes.listItemButton}
          variant='raised'
          color='primary'
          onClick={()=>{
            setOpenPhrase((openPhrase) => !openPhrase);
          }}>
          {'definir frase de segurança '}&nbsp;<FontAwesomeIcon icon='pencil-alt' style={{marginBottom:'4px', marginLeft:'8px'}}/>
          </Button>
          { openPhrase ?
        <form onSubmit={handleSubmit}>
          <AccountListItemField
            primary={t('forms.safetyPhrase.label')}
            i18nScope="forms.safetyPhrase"
            name="safety_phrase"
            canEdit
            tooltipTitle={t('info.securityPhrase')}
            listItemSecondaryAction={(
          <SubmitButton
            submitText={t('common.save')}
            disabled={submitting || (pristine || invalid)}
          />)}/>
        </form>:<div></div>}

        </Grid>
        {hasPin &&         
        <Grid item className={classes.listItem} sm={6} xs={12}>
          <Button
          className={classes.listItemButton}
          variant="contained"
          color="primary"
          onClick={pinIsRequiredToWithdrawalDialog}>
            {t('security.changePin')}&nbsp;<Security fontSize={10} />
          </Button>
        </Grid>}

        <Grid item className={classes.listItem} sm={6} xs={12}>
          <Button
          classes={{root: classes.listItemButton}}
          variant="contained"
          color="primary"
          onClick={()=>{
              generateOtp({requestGenerateOtp, otp_active})
              setOpen2fa(true);
          }}>
          {
            otp_active
              ? t('security.requestRegenerateOtp')
              : <div>Gerar chave 2fa&nbsp;<FontAwesomeIcon icon='key' style={{marginBottom:'1px', marginLeft:'8px'}}/></div>
          }
          </Button>
        </Grid>
      </Grid>

      {open2fa ? <OtpForm/> : <div></div>}

    </>
  );
};

const schemas = {
  safety_phrase: {
    type: String,
    max: 30,
  },
};

const mapStateToProps = ({user, appData}) => ({
  safety_phrase: user.profile.safety_phrase,
  otp_active: Number(user.profile.otp_active),
  qrCodeInfos: appData.service.generateOtp,
  kycLevel: user.profile.kyc_level,
  hasPin: user.profile.has_pin,
});


const maspDispatchToProps = dispatch => ({
  requestGenerateOtp: () => dispatch(generateOtpRequested()),
  updateProfile: profile => dispatch(updateProfileRequested(profile)),
  pinIsRequiredToWithdrawalDialog: () => {
    dispatch(
      addDialog({
        title: "common.attention",
        centerTitle: true,
        renderComponent: "pinIsRequiredToWithdrawalWarning",
        disableBackdropClick: true,
        availableChoices: [
          {
            label: "common.understood",
            actionToTake: "",
            color: "secondary",
            variant: "raised",
          },
        ],
      })
    );
  },
});

const enhance = compose(
  withStyles(styles),
  translate(),
  connect(mapStateToProps, maspDispatchToProps),
  renderBranch(['otp_active']),
  withState('openPhrase', 'setOpenPhrase', false),
  withState('open2fa', 'setOpen2fa', false),
  withHandlers({
    generateOtp: (props) => ({requestGenerateOtp, otp_active}) => {
      const kycLevel = Number(props.kycLevel);
      if(otp_active)
          window.open(kycLevel < 2 || isNaN(kycLevel) ? OTP_DISABLE_LOW_LEVEL_USERS_URL : OTP_DISABLE_HIGH_LEVEL_USERS_URL , '_blank');
       else
          requestGenerateOtp();
    },
    onSubmit: (props) => values => {
      const { updateProfile } = props;
      updateProfile(values);
    },
  }),
  withProps(({ safety_phrase }) => (
    { initialValues: { safety_phrase } })),
  reduxForm({
    form: 'credentials',
    validate: validate(schemas),
    enableReinitialize: true,
  }),
  pure,
);

export default enhance(GeneralInfoFormAndOtpButton);
