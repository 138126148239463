export const formStepsData = [
  {
    title: "forms.pinCreateDialog.educativeDialog.title",
    description: "forms.pinCreateDialog.educativeDialog.description",
    stepComponent: "PinCreateFormStep1",
    tips: [
      'forms.pinCreateDialog.educativeDialog.tipOne',
      'forms.pinCreateDialog.educativeDialog.tipTwo',
      'forms.pinCreateDialog.educativeDialog.tipThree',
    ],
  },
  {
    title: "forms.pinCreateDialog.createPinStep.title",
    description: "forms.pinCreateDialog.createPinStep.description",
    submitButtonLabel: "forms.pinCreateDialog.createPinStep.submitButton",
    stepComponent: "PinCreateFormStep2",
    inputName: 'pin',
  },
  {
    title: "forms.pinCreateDialog.confirmPinStep.title",
    description: "forms.pinCreateDialog.confirmPinStep.description",
    submitButtonLabel: "forms.pinCreateDialog.confirmPinStep.submitButton",
    stepComponent: "PinCreateFormStep3",
    isFinalStep: true,
    inputName: 'confirmPin',
  },
];
